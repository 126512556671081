import { Modal, Select, Tag } from "antd"
import { useState, useEffect } from "react"
import { DatePicker } from "antd";
import axios from 'axios';
import { BASE_URL } from "../../Constants/BaseUrl";
import { Hourglass } from 'react-loader-spinner';

const { Option } = Select;
const Insurance = ({ isInsuranceOpen, setIsInsuranceOpen, setUsdots, setLoading,companies,setCompanies,companyName,setCompanyName,handleRemove,selections,setSelections,insurUsdot,setInsurUsdot,selectedDate,setSelectedDate,selectedDate1,setSelectedDate1,removeDates }) => {
    // const [selectedDate, setSelectedDate] = useState(null);
    // const [selectedDate1, setSelectedDate1] = useState(null);
     
    // const [selections, setSelections] = useState("");
    const [from, setFrom] = useState(null);
    const [type, setType] = useState('Include');
    const [to, setTo] = useState(null);
    
    // const [insurUsdot, setInsurUsdot] = useState({})
    const [backColor, setBackcolor] = useState({ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" })

    const getData = async (q) => {
        try {
            const response = await axios.post(`${BASE_URL}/getData`, { query: q });
            if (response.data.hasOwnProperty('result')) {
                const list = response.data['result']
                return list
            }
        } catch (error) {
            return []
        }
    }
    function intersection(...lists) {
        const arrays = lists.map(list => Array.from(list));
        if (arrays.length === 0) return [];
        return arrays[0].filter(item => arrays.every(array => array.includes(item)));
    }

    const handleApply = () => {
        const values = companyName.length == 0 ? '' : companyName.map(item => `'${item}'`).join(',');
        setUsdots({ companies: values, include: type === 'Include', dates: insurUsdot })
        setIsInsuranceOpen(false)
    }

    const handleDateChange = (date, dateString) => {
        setSelectedDate(date);
        console.log(date)
        setFrom(dateString)
        setTo(from)
    };
    const handleDateChange1 = (date, dateString) => {
        setSelectedDate1(date);
        setTo(dateString)
        setInsurUsdot({ from: from, to: dateString })
    };
    const handleChange = (company) => {
        const isSelected = companyName.includes(company);
        if (!isSelected) {
            setCompanyName([...companyName, company]);
        }
        console.log(companyName)
    };
   console.log(insurUsdot,"insuranceDote")
    
    // const removeDates = () => {
    //     setSelections("")
    //     setInsurUsdot({})
    //     setSelectedDate(null)
    //     setSelectedDate1(null)
    // }
    useEffect(() => {
        if (isInsuranceOpen) {
            const query = `select distinct insurance_company_name from actpendinsur_allwithhistory order by insurance_company_name`
            getData(query).then((data) => {
                console.log(data)
                setCompanies(data)
            }).catch((error) => {
                console.error('Error:', error);
            })
        }
    }, [isInsuranceOpen]);

    useEffect(() => {
        if(insurUsdot.hasOwnProperty('to')){
            setSelections("Policies Expiring between " + insurUsdot.from + " and " + insurUsdot.to)
        }
    }, [insurUsdot])

    useEffect(() => {
        if (type === 'Include') {
            setBackcolor({ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" })
        } else {
            setBackcolor({ backgroundColor: "#FE27271A", border: "1px solid #FE2727" })
        }
    }, [type])
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);

    const disabledDate = (current) => {
        return current && current < minDate;
    };
    const disabledDate1 = (current) => {
        return current && current < selectedDate;
    };

    return (
        <>
            <Modal
                wrapClassName="modalofall"
                open={isInsuranceOpen}
                onCancel={() => setIsInsuranceOpen(false)}
                footer={null}
                width={1104}
                closeIcon={null}
                centered={false}
            >
                <div className="headerofmodal">
                    <span className="headertext">Insurance</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setIsInsuranceOpen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="WraperofModal">
                    <div className="input_days">
                        <div>
                            <span className="title_date"> From</span>

                            <DatePicker

                                style={{
                                    width: '516px',
                                    height: '56px',
                                    flexShrink: 0,
                                    borderRadius: '4px',
                                    border: '1px solid #DFE1E2',
                                    background: '#FFF',
                                }}
                                disabledDate={disabledDate}
                                placeholder="30/00/0000"
                                format="YYYY/MM/DD"
                                onChange={handleDateChange}
                                value={selectedDate}
                                suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                            />
                        </div>
                        <div >
                            <span className="title_date"> To</span>
                            <DatePicker

                                style={{
                                    width: '516px',
                                    height: '56px',
                                    flexShrink: 0,
                                    borderRadius: '4px',
                                    border: '1px solid #DFE1E2',
                                    background: '#FFF',
                                }}
                                placeholder="30/00/0000"
                                format="YYYY/MM/DD"
                                disabledDate={disabledDate1}
                                disabled={selectedDate == null}
                                onChange={handleDateChange1}
                                defaultPickerValue={selectedDate?selectedDate:""}
                                value={selectedDate1?selectedDate1:""}
                                suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                            />
                        </div>

                    </div>
                </div>
                <div className="coverofmodalforlength">
                    <div className="cardcomponent">
                        <div className="card-header">
                            Insurance companies
                        </div>
                        <div className="row_wrapper">
                            <div className="d-flex">
                                <div className="cover_of_first_select">
                                    <Select
                                        className="custom-select-insurance "
                                        defaultValue="Include"
                                        style={{ minWidth: 120, height: 40, border: 'none' }}
                                        onChange={value => setType(value)}
                                        suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M13 6L8 11L3 6" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>}
                                    >
                                        <Option value="Include">Include</Option>
                                        <Option value="Exclude">Exclude</Option>
                                    </Select>
                                </div>

                                <Select
                                    suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M13 6L8 11L3 6" stroke="#374147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>}
                                    className="custom-select-insurance mx-3"
                                    style={{ minWidth: "80%", height: 40, border: 'none' }}
                                    placeholder="Select companies"
                                    onChange={value => handleChange(value)}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {companies.map((option, index) => (
                                        <Option value={option}>{option}</Option>
                                    ))}
                                </Select>
                            </div>




                        </div>

                    </div>
                    <div className="mt-3">
                    {companyName.map((company, index) => (
                        <Tag
                            key={company}
                            closable
                            onClose={() => handleRemove(company)}
                            style={backColor}
                        >
                            {company}
                        </Tag>
                    ))}
                    {
                        selections !== "" &&
                        <Tag
                            key={selections}
                            closable
                            onClose={() => removeDates()}
                            style={{ backgroundColor: "rgba(12, 148, 228, 0.1)", border: "1px solid #0C94E4" }}
                        >
                            {selections}
                        </Tag>

                    }
</div>
                </div>
                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: "pointer" }} onClick={() => { setIsInsuranceOpen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={() => { handleApply() }}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Insurance