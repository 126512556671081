import { useState } from "react"
import "../Chameleon.css"
import { Link } from "react-router-dom"
import DeepDrive from "./DeepDrive"
const Deepdive=()=>{
    const [submit, setSubmit] = useState(true)

    return (
        <>

            <div >
                {
                    submit ? (
                        <>
                            <div className="coverofdeep mx-5 mt-4">
                                <Link to="/">


                                    <span className="backtopost"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path d="M6.25 8.125L3.125 5L6.25 1.875" stroke="#666D72" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg> Back to list</span></Link>
                            </div>
                            <div className="Chameleon">

                                <span className="Chameleontitle">
                                    Deep drive
                                </span>
                                <p className="Chameleontext">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <span className="usdottitle">USDOT number</span>
                                <input type="text" placeholder="Ex. 2284379" className="usdotinput" />
                                <div className="submitbtn butonhover" onClick={() => { setSubmit(false) }}>
                                    Submit
                                </div>
                            </div>
                        </>
                    ) : (<DeepDrive />)
                }

            </div>
        </>
    )
}
export default Deepdive;