import React from 'react'
import "./Account.css"
import { Link, useLocation } from 'react-router-dom'

function AccountNav() {
    const location = useLocation();
    
    // Function to check if a tab is active
    const isActive = (path) => location.pathname === path ? 'active-tab' : '';

    return (
        <div className='acc_nav_parent'>
            <Link to='/home/myinfo'> 
                <div className={`navitem_acc ${isActive('/home/myinfo')}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.98438 15.5781C5.45462 14.6519 6.17216 13.874 7.05745 13.3306C7.94275 12.7872 8.96123 12.4995 10 12.4995C11.0388 12.4995 12.0572 12.7872 12.9425 13.3306C13.8278 13.874 14.5454 14.6519 15.0156 15.5781" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>   
                    My Info
                </div>
            </Link>

            <Link to='/home/agencyinfo'>
                <div className={`navitem_acc ${isActive('/home/agencyinfo')}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M1.25 16.875H18.75" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.25 16.875V3.125C11.25 2.95924 11.1842 2.80027 11.0669 2.68306C10.9497 2.56585 10.7908 2.5 10.625 2.5H3.125C2.95924 2.5 2.80027 2.56585 2.68306 2.68306C2.56585 2.80027 2.5 2.95924 2.5 3.125V16.875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17.5 16.875V8.125C17.5 7.95924 17.4342 7.80027 17.3169 7.68306C17.1997 7.56585 17.0408 7.5 16.875 7.5H11.25" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5 5.625H7.5" stroke="#374147" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.25 10.625H8.75" stroke="#374147" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5 13.75H7.5" stroke="#374147" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.75 13.75H15" stroke="#374147" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.75 10.625H15" stroke="#374147" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>  
                    Agency Info
                </div>
            </Link>

            <Link to='/home/finances'>
                <div className={`navitem_acc ${isActive('/home/finances')}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 5.625V6.875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 13.125V14.375" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.125 13.125H10.9375C11.3519 13.125 11.7493 12.9604 12.0424 12.6674C12.3354 12.3743 12.5 11.9769 12.5 11.5625C12.5 11.1481 12.3354 10.7507 12.0424 10.4576C11.7493 10.1646 11.3519 10 10.9375 10H9.0625C8.6481 10 8.25067 9.83538 7.95765 9.54235C7.66462 9.24933 7.5 8.8519 7.5 8.4375C7.5 8.0231 7.66462 7.62567 7.95765 7.33265C8.25067 7.03962 8.6481 6.875 9.0625 6.875H11.875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>    
                    Finances
                </div>
            </Link>

            <Link to='/home/collaborators'>
                <div className={`navitem_acc ${isActive('/home/collaborators')}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M6.875 12.5C9.11866 12.5 10.9375 10.6812 10.9375 8.4375C10.9375 6.19384 9.11866 4.375 6.875 4.375C4.63134 4.375 2.8125 6.19384 2.8125 8.4375C2.8125 10.6812 4.63134 12.5 6.875 12.5Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.75 17.1875C13.75 15.0844 11.8391 13.4375 9.375 13.4375H4.375C1.91087 13.4375 0 15.0844 0 17.1875" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15 6.875C16.3807 6.875 17.5 5.75573 17.5 4.375C17.5 2.99427 16.3807 1.875 15 1.875C13.6193 1.875 12.5 2.99427 12.5 4.375C12.5 5.75573 13.6193 6.875 15 6.875Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M19.0625 11.25C19.0625 10.0094 17.6594 8.9375 16.25 8.9375H13.75C12.3406 8.9375 10.9375 10.0094 10.9375 11.25" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> 
                    Collaborators
                </div>
            </Link>
        </div>
    )
}

export default AccountNav;
