// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wraperofcrash .operationalclassification{
    margin-top: 20px;
    width: 49%;
}
.wraperofcrash{
    display: flex;
    justify-content: space-between;
}
.wraperofcrash .card-header{
    width: 100%;
}
.coverofcrash{
    display: flex;
}
.induvidual{
    width: 50%;
    row-gap: 30px;
    display: grid;
    padding: 20px;
}
.coverofclass{
    display: grid;
}
.mainworker .induvidual{
    width: auto;  
}
.mainworker{
    column-gap: 90px;
}
  table {
    border-collapse: separate;
    border-spacing: 10px;
    width: 100%;
  }

  th, td {
    padding: 10px;
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/CrashInspection/Crash.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;EACE;IACE,yBAAyB;IACzB,oBAAoB;IACpB,WAAW;EACb;;EAEA;IACE,aAAa;EACf","sourcesContent":[".wraperofcrash .operationalclassification{\n    margin-top: 20px;\n    width: 49%;\n}\n.wraperofcrash{\n    display: flex;\n    justify-content: space-between;\n}\n.wraperofcrash .card-header{\n    width: 100%;\n}\n.coverofcrash{\n    display: flex;\n}\n.induvidual{\n    width: 50%;\n    row-gap: 30px;\n    display: grid;\n    padding: 20px;\n}\n.coverofclass{\n    display: grid;\n}\n.mainworker .induvidual{\n    width: auto;  \n}\n.mainworker{\n    column-gap: 90px;\n}\n  table {\n    border-collapse: separate;\n    border-spacing: 10px;\n    width: 100%;\n  }\n\n  th, td {\n    padding: 10px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
