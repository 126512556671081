import React, { useEffect, useState } from 'react'
import AccountNav from "../Navbar/AccountNav"
import editimg from "./Images/editimg.png"
import "./user.css"
import axios from 'axios'
import {  Modal } from 'antd'
import Carousel from './Carousel'
import './style.css'
import './pricing.css'
import './App.css'
import PaymentFormComponent from './PaymentFormComponent '
import { SERVER_URL } from '../Constants/BaseUrl'
import { useNavigate } from 'react-router-dom'
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0]; // Get the date part (YYYY-MM-DD)
};

// Helper function to calculate the next payment date (+30 days)
const getNextPaymentDate = (dateString) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 30); // Add 30 days
  return date.toISOString().split('T')[0]; // Get the formatted date
};
function Finances() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [pricemodal, setPricemodal] = useState(false)
  const [deltemodal, setDeltemodal] = useState(false);

  useEffect(() => {
    // Fetch user ID from localStorage
    const userId = localStorage.getItem('userId');

    // Make GET request to fetch user data based on ID
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/users/${userId}`);
        setUser(response.data.data);
        setLoading(false); // Set loading to false after successful data fetch
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false); // Set loading to false on error
        // Handle error
      }
    };
  

    if (userId) {
      fetchUserData();
    }
  }, []); // Empty dependency array ensures this effect runs only once
  const navigate = useNavigate()
  const cancelsubscription = async () => {
    setLoading(true); // Show loading spinner
  
    try {
      const response = await axios.post(`${SERVER_URL}/payment/cancel`, {
        subscriptionId: user.customerId
      });
  
      // Handle response if needed (e.g., check if the cancellation was successful)
      if (response.status === 200) {
        setDeltemodal(false); // Close the modal on success
        localStorage.clear();  // Clear local storage
        navigate("/login");    // Navigate to login page
      } else {
        console.error('Cancellation failed:', response.data);
        // Optionally show an error message to the user
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      // Optionally show an error message to the user
    } finally {
      setLoading(false); // Hide loading spinner regardless of success or error
    }
  };
  
  const renderPurchasedCategory = () => {
    if (loading) return 'Loading...';
    switch (user?.purchasedcategory) {
      case 'Starter':
        return 'Starter | $99 / monthly';
      case 'Business':
        return 'Business | $299 / monthly';
      case 'Enterprise':
        return 'Enterprise | $499 / monthly';
      default:
        return user?.purchasedcategory;
    }
  };
  const startDate = user?.startDate;
  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const nextPaymentDate = startDate ? getNextPaymentDate(startDate) : '';
  return (
    <div className='user_main'>
      <div  >
        <AccountNav />
      </div>
      <div className='main_content'>
        <div className='inner_account'>
          {/* user name email profile image section */}


          {/* user all information  */}
          <div className='boxinfo'>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Plan: </div>
                <div className='usertitleinfo'>
                  {renderPurchasedCategory()}

                </div>
              </div>
              <img className='editimage' src={editimg} alt='edit image'onClick={() => setPricemodal(true)} ></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Recurring Credit Card #: </div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.cardNumber}</div>
              </div>
              <img className='editimage' src={editimg} alt='edit image'onClick={()=>{setShowModal(true)}} ></img>

            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Exp date:</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.expirationDate}</div>
              </div>
              <div className='d-flex gap-3'>


              </div>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>CVV: </div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.cardCode}</div>
              </div>

            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Billing address:</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.billingAddress.address}</div>
              </div>

            </div>
            <div className='userstrap_white'>
        <div className='d-flex gap-3'>
          <div className='usertitle'>Last payment:</div>
          <div className='usertitleinfo'>{loading ? 'Loading...' : formattedStartDate}</div>
        </div>
      </div>
      
      <div className='userstrap_grey'>
        <div className='d-flex gap-3'>
          <div className='usertitle'>Next payment:</div>
          <div className='usertitleinfo'>{loading ? 'Loading...' : nextPaymentDate}</div>
        </div>
      </div>
            <div className='btnfordelete' onClick={()=>{setDeltemodal(true)}}>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M20.25 5.25H3.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 9.75V15.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.25 9.75V15.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.75 5.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H9.75C9.35218 2.25 8.97064 2.40804 8.68934 2.68934C8.40804 2.97064 8.25 3.35218 8.25 3.75V5.25" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Cancel Plan
    </div>

  </div>
          </div>

        </div>
      </div>



      <Modal
        title={null}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={985}
        closeIcon={null}
      // getContainer={() => document.getElementById('offcanvasRight')} // Mount modal inside offcanvas
      >
          <div className="headerofmodal">
                    <span className="headertext">        Change Recurring Billing Info
                    </span>
                    <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowModal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M16 2L2 16"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 16L2 2"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
<PaymentFormComponent subscriptionId={user?._id}/>
      </Modal>
      <Modal
        title={null}
        open={pricemodal}
        onCancel={() => setPricemodal(false)}
        footer={null}
        width={1300}
        closeIcon={null}
      // getContainer={() => document.getElementById('offcanvasRight')} // Mount modal inside offcanvas
      >
      
        <div className="headerofmodal">
                    <span className="headertext">        Change Recurring Plan Info
                    </span>
                    <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => setPricemodal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M16 2L2 16"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 16L2 2"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
        <div className='mt-4'>
        <Carousel customerId={user?.customerId}/>
        </div>

      </Modal>
      <Modal
                open={deltemodal}
                onCancel={() => setDeltemodal(false)}
                footer={null}
                closeIcon={null}
            >
                <div className="headerofmodal">
                    <span className="headertext">Cancel Plan</span>
                    <svg
                        style={{ cursor: "pointer" }}
                        onClick={() => setDeltemodal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <path
                            d="M16 2L2 16"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16 16L2 2"
                            stroke="#374147"
                            strokeWidth="2.625"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <div className="bodyofmodal">
                <div className="coverofsubtitle subtitles">
                      
                      <div className="boxofpeople">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M25 17H31" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.5 20C17.6421 20 21 16.6421 21 12.5C21 8.35786 17.6421 5 13.5 5C9.35786 5 6 8.35786 6 12.5C6 16.6421 9.35786 20 13.5 20Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
  <path d="M2.77539 25C4.08946 23.4338 5.7306 22.1744 7.58347 21.3104C9.43633 20.4463 11.456 19.9985 13.5004 19.9985C15.5448 19.9985 17.5644 20.4463 19.4173 21.3104C21.2702 22.1744 22.9113 23.4338 24.2254 25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
         </div>
                 <span className="contentofbus mt-2">Are you sure you want to cancel this plan?</span>
                 <span className="contentof">{user?.purchasedcategory} Plan | Monthly</span>
                 <div className="deleteclientbtn" onClick={() => { setDeltemodal(false) }}>
                 No, don’t cancel
                 </div>
                 <span className="cancelbutton" onClick={cancelsubscription} > {loading ? (<>Cancelling...</>):(<>Continue Cancel Plan</>)} </span>

               </div>
                </div>
            </Modal>
    </div>
  )
}

export default Finances;