import React from 'react';
import { Link } from "react-router-dom";
import { Table, Space } from 'antd';
import { MailOutlined, PhoneOutlined, GlobalOutlined, FileTextOutlined } from '@ant-design/icons';
import "./carier.css";

const Carrier = () => {

    return (
        <>
            <div className="carriercontain">
                <Link to="/carierdata">
                    <div className="button_of_search">
                        New search
                    </div>
                </Link>
                <div className="carierCover" >
                 <div style={{display:"flex", justifyContent:"center"}}>
                    working on it
                 </div>
                </div>
            </div>
        </>
    );
};

export default Carrier;