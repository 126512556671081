import "./handlebook.css"
import titleimage from "../../Images/Rectangle 38.png"
import { Link } from "react-router-dom"
const Marketing = () => {
    const allblog = [
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },
        { image: titleimage, title: "Lorem ipsum dolor sit amet", desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip,", date: "09/22/2023", tag: "Insurance" },

    ]
    return (
        <div className="bookcover">
            <div className="Maintitleofbook">
                Marketing handbook
            </div>
            <Link to="/sales/detail">
                <div className="coverforblogs">
                    {
                        allblog.map((blog, index) => {
                            return (
                                <>
                                    <div className="bloger">
                                        <img src={blog.image} alt="image" />
                                        <div className="tagcontent">
                                            <div className="tag">
                                                Insurance
                                            </div>
                                            <div className="blogdate">
                                                {blog.date}
                                            </div>
                                        </div>
                                        <div className="blogtitle">{blog.title}</div>
                                        <p className="deccriptiontext">{blog.desc}</p>
                                    </div>

                                </>
                            )

                        })
                    }
                </div></Link>
        </div>
    )
}
export default Marketing;
