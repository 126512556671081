// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navitem_acc{
margin-top: 32px;
cursor: pointer;
color: #374147;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
gap: 10px;
}
.acc_nav_parent{
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    border-right: 2px solid #DFE1E2;
    width: 200px;
    height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/Navbar/Account.css"],"names":[],"mappings":"AAAA;AACA,gBAAgB;AAChB,eAAe;AACf,cAAc;AACd,mBAAmB;AACnB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB,aAAa;AACb,mBAAmB;AACnB,SAAS;AACT;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,+BAA+B;IAC/B,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".navitem_acc{\nmargin-top: 32px;\ncursor: pointer;\ncolor: #374147;\nfont-family: Roboto;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 500;\nline-height: normal;\ndisplay: flex;\nalign-items: center;\ngap: 10px;\n}\n.acc_nav_parent{\n    display: flex;\n    flex-direction: column;\n    margin-left: 32px;\n    border-right: 2px solid #DFE1E2;\n    width: 200px;\n    height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
