const InsuranceHistory =()=>{
    return(
        <>
       <div className="cardcomponent">
                                <div className="card-header">
                                Active/ Pending insurance
                                </div>
                             <div className="compinfo">
                                <div className="coverofcompinfo  ">
                                    <div className="infosection">
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Form:
                                            </span>
                                            <span className="descofinfo">
                                            91X
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Coverage from:
                                            </span>
                                            <span className="descofinfo">
                                            $0
                                            </span>
                                        </div>
                                        
                                    </div>
                                    <div className="infosection">
                                       
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Type:
                                            </span>
                                            <span className="descofinfo">
                                            BIPD/ Primary
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Coverage to:
                                            </span>
                                            <span className="descofinfo">
                                            $750.000
                                            </span>
                                        </div>
                                       
                                    </div>
                                    <div className="infosection">
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Insurance carrier:
                                            </span>
                                            <span className="descofinfo">
                                            <u>PROGRESSIVE COUNTY MUTUAL</u>
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Received date:
                                            </span>
                                            <span className="descofinfo">
                                            11/22/2022
                                            </span>
                                        </div>
                                       
                                    </div>
                                    <div className="infosection">
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Policy/ Surety:
                                            </span>
                                            <span className="descofinfo">
                                            CA963639075
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                            Rejected date:
                                            </span>
                                            <span className="descofinfo">
                                            02/14/2023 - Replaced
                                            </span>
                                        </div>
                                      
                                    </div>

                                </div>
                               
                             </div>
                            </div>
        </>
    )
}
export default InsuranceHistory;