import React, { useState } from 'react';
import "./Detail.css";
import CarrierDetails from "./CarrierDetails"
import ActivePendingInsurance from './ActivePendingInsurance';
import RejectedInsurance from './RejectedInsurance';
import InsuranceHistory from './InsuranceHistory';
import Revocation from './Revocation';
import AuthorityHistory from './AuthorityHistory';
import InspectionDate from './InspectionDate';


const Nav = () => {
  const [activeLink, setActiveLink] = useState('Carrier details');

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  // Render the respective page component based on the activeLink state
  const renderPage = () => {
    switch (activeLink) {
      case 'Carrier details':
        return <CarrierDetails />;
      case 'Active/ Pending insurance':
        return <ActivePendingInsurance />;
      case 'Rejected insurance':
        return <RejectedInsurance />;
      case 'Insurance history':
        return <InsuranceHistory />;
      case 'Authority history':
        return <AuthorityHistory />;
      case 'Revocation':
        return <Revocation />;
      case 'Inspection date':
        return <InspectionDate />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="titleofnav">
        Company details
      </div>
      <div className="navcontentlinks">
        <span
          className={`navlinkofbusiness ${activeLink === 'Carrier details' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Carrier details')}
        >
          Carrier details
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Active/ Pending insurance' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Active/ Pending insurance')}
        >
          Active/ Pending insurance
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Rejected insurance' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Rejected insurance')}
        >
          Rejected insurance
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Insurance history' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Insurance history')}
        >
          Insurance history
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Authority history' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Authority history')}
        >
          Authority history
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Revocation' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Revocation')}
        >
          Revocation
        </span>
        <span
          className={`navlinkofbusiness ${activeLink === 'Inspection date' ? 'activeblue' : ''}`}
          onClick={() => handleNavLinkClick('Inspection date')}
        >
          Inspection date
        </span>
      </div>

      {/* Render the respective page component */}
      {renderPage()}
    </>
  );
}

export default Nav;