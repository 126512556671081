import { useState } from "react"
import "./Chameleon.css"
import Usdot from "./Usdot"
import { Link } from "react-router-dom"
const Carierdata=()=>{
    return (
        <div >
            <div className="Chameleon">
                <span className="Chameleontitle">
                    Carrier MGA
                </span>
                <p className="Chameleontext">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <span className="usdottitle">Company name</span>
                <input type="text" placeholder="Ex. Insurance company" className="usdotinput" />
                <div className="lineofhr">
                    <hr></hr><span className="orofhr">or</span><hr></hr>

                </div>
                <span className="usdottitle">NAIC number</span>
                <input type="text" placeholder="Ex. 2284379" className="usdotinput" />
                <Link to="/carierpage">

                    <div className="submitbtn butonhover">
                        Submit
                    </div> </Link>
            </div>

        </div>
    )
}
export default Carierdata