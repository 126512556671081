import { Modal, Select, DatePicker } from "antd"
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
import {Tag} from "antd";
const Safety = ({ saftyOpen, setSaftyOpen }) => {
    const initialCheckboxes = {
        violation1: { checked: false, active: false },
        violation2: { checked: false, active: false },
        violation3: { checked: false, active: false },
        violation4: { checked: false, active: false },
        violation5: { checked: false, active: false },
        rating: { checked: false, active: false },
        satisfactory: { checked: false, active: false },
        conditional: { checked: false, active: false },
        unSatisfactory: { checked: false, active: false },
        oosCarriers: { checked: false, active: false },
        chameleonCarrier: { checked: false, active: false }
    };

    const [checkedBoxes, setCheckedBoxes] = useState(initialCheckboxes);
    const handleChange = (field) => {
        setCheckedBoxes((prevState) => ({
            ...prevState,
            [field]: {
                ...prevState[field],
                checked: !prevState[field]?.checked,
                active: false
            }
        }));

    };
    const toggleActive = (field) => {
        setCheckedBoxes((prevState) => ({
            ...prevState,
            [field]: {
                ...prevState[field],
                active: !prevState[field]?.active,
                checked: false,
            }
        }));
    };

    const renderSelectedTags = () => {
        const selectedTags = [];

        Object.keys(checkedBoxes).forEach((key) => {
            const { checked, active } = checkedBoxes[key];

            if (checked || active) {
                const color = active ? 'red' : 'green';

                selectedTags.push(
                    <div  key={key}>
                      
                        <Tag color={color}>{`Violation ${key.charAt(key.length - 1)}`}</Tag>
                    </div>
                );
            }
        });

        return selectedTags;
    };




    return (
        <>
            <Modal
                wrapClassName="modalofall"
                open={saftyOpen}
                onCancel={() => setSaftyOpen(false)}
                footer={null}
                width={1112}
                closeIcon={null}
                centered={false}
            >

                <div className="headerofmodal">
                    <span className="headertext">Safety</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setSaftyOpen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="WraperofModal wrapperof">
                    <div >
                        <div className="cardcomponent" style={{ width: "321px" }}>
                            <div className="card-header">
                                Safety ratings
                            </div>
                            <div className="row_wrapper_COVER">
                                <div className="itemof_covers_Authority">
                                    <div className="tag_coverside">
                                        No Rating
                                    </div>
                                    <div className="tag_cover">


                                        <Checkbox
                                            checked={checkedBoxes?.rating?.checked}
                                            onChange={(event) => handleChange("rating")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.rating?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("rating")}
                                        >
                                            {checkedBoxes?.rating?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>
                                    </div>
                                </div>
                                <div className="itemof_covers_Authority">
                                    <div className="tag_coverside">
                                        Satisfactory
                                    </div>
                                    <div className="tag_cover">

                                        <Checkbox
                                            checked={checkedBoxes?.satisfactory?.checked}
                                            onChange={(event) => handleChange("satisfactory")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.satisfactory?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("satisfactory")}
                                        >
                                            {checkedBoxes?.satisfactory?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>
                                    </div>
                                </div>
                                <div className="itemof_covers_Authority">
                                    <div className="tag_coverside">
                                        Conditional
                                    </div>
                                    <div className="tag_cover">
                                        <Checkbox
                                            checked={checkedBoxes?.conditional?.checked}
                                            onChange={(event) => handleChange("conditional")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.conditional?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("conditional")}
                                        >
                                            {checkedBoxes?.conditional?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>

                                    </div>
                                </div>
                                <div className="itemof_covers_Authority_end">
                                    <div className="tag_coverside">
                                        Unsatisfactory
                                    </div>
                                    <div className="tag_cover">

                                        <Checkbox
                                            checked={checkedBoxes?.unSatisfactory?.checked}
                                            onChange={() => handleChange("unSatisfactory")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.unSatisfactory?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("unSatisfactory")}
                                        >
                                            {checkedBoxes?.unSatisfactory?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>
                                    </div>
                                </div>



                            </div>


                        </div>
                        <div className="cardcomponent" style={{ width: "321px" }}>
                            <div className="card-header">
                                Out of service
                            </div>
                            <div className="row_wrapper_COVER">


                                <div className="itemof_covers_Authority_end p-2">
                                    <div className="tag_coverside">
                                        OOS Carriers
                                    </div>
                                    <div className="tag_cover">

                                        <Checkbox
                                            checked={checkedBoxes?.oosCarriers?.checked}
                                            onChange={() => handleChange("oosCarriers")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.oosCarriers?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("oosCarriers")}
                                        >
                                            {checkedBoxes?.oosCarriers?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>
                                    </div>
                                </div>



                            </div>


                        </div>
                        <div className="cardcomponent" style={{ width: "321px" }}>
                            <div className="card-header">
                                Cameleon
                            </div>
                            <div className="row_wrapper_COVER">


                                <div className="itemof_covers_Authority_end p-2">
                                    <div className="tag_coverside">
                                        Cameleon Carriers
                                    </div>
                                    <div className="tag_cover">

                                        <Checkbox
                                            checked={checkedBoxes?.chameleonCarrier?.checked}
                                            onChange={() => handleChange("chameleonCarrier")}
                                        />

                                        <button
                                            className={`toggle-btn ${checkedBoxes?.chameleonCarrier?.active ? 'active' : ''}`}
                                            onClick={() => toggleActive("chameleonCarrier")}
                                        >
                                            {checkedBoxes?.chameleonCarrier?.active ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : null}
                                        </button>
                                    </div>
                                </div>



                            </div>


                        </div>

                    </div>

                    <div className="coverfor_bottom_section">


                        <div className="coverof_Authority">
                            <div className="cardcomponent" style={{ width: "693px" }}>
                                <div className="card-header">
                                    Operation authority
                                </div>
                                <div className="d-flex p-3">
                                {renderSelectedTags()}
                                </div>
                                <div className="row_wrapper_Calender">
                                    <div className="mainins">
                                        Type of violation
                                    </div>
                                    <div className="tag_cover truck_side mt-3">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                checked={checkedBoxes.violation1.checked}
                                                onChange={() => handleChange("violation1")}
                                            />
                                            <button
                                                className={`toggle-btn ${checkedBoxes.violation1.active ? 'active' : ''}`}
                                                onClick={() => toggleActive("violation1")}
                                            >
                                                {checkedBoxes.violation1.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        Violation 1
                                    </div>
                                    <div className="tag_cover truck_side ">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                checked={checkedBoxes.violation2.checked}
                                                onChange={() => handleChange("violation2")}
                                            />
                                            <button
                                                className={`toggle-btn ${checkedBoxes.violation2.active ? 'active' : ''}`}
                                                onClick={() => toggleActive("violation2")}
                                            >
                                                {checkedBoxes.violation2.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        Violation 2
                                    </div>
                                    <div className="tag_cover truck_side">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                checked={checkedBoxes.violation3.checked}
                                                onChange={() => handleChange("violation3")}
                                            />
                                            <button
                                                className={`toggle-btn ${checkedBoxes.violation3.active ? 'active' : ''}`}
                                                onClick={() => toggleActive("violation3")}
                                            >
                                                {checkedBoxes.violation3.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        Violation 3
                                    </div>
                                    <div className="tag_cover truck_side">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                checked={checkedBoxes.violation4.checked}
                                                onChange={() => handleChange("violation4")}
                                            />
                                            <button
                                                className={`toggle-btn ${checkedBoxes.violation4.active ? 'active' : ''}`}
                                                onClick={() => toggleActive("violation4")}
                                            >
                                                {checkedBoxes.violation4.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        Violation 4
                                    </div>
                                    <div className="tag_cover truck_side">
                                        <div className="d-flex align-items-center">
                                            <Checkbox
                                                checked={checkedBoxes.violation5.checked}
                                                onChange={() => handleChange("violation5")}
                                            />
                                            <button
                                                className={`toggle-btn ${checkedBoxes.violation5.active ? 'active' : ''}`}
                                                onClick={() => toggleActive("violation5")}
                                            >
                                                {checkedBoxes.violation5.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        Violation 5
                                    </div>
                                </div>




                            </div>



                        </div>


                    </div>


                </div>

                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: "pointer" }} onClick={() => { setSaftyOpen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={() => setSaftyOpen(false)}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Safety;