import { Button, Drawer } from 'antd';
import { useState } from 'react';

const Sendmessage=()=>{
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="svgcontaindownload3" onClick={showDrawer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17.1479 9.45318L3.95262 2.06255C3.84147 2.00115 3.71425 1.97506 3.58791 1.98775C3.46156 2.00045 3.34208 2.05132 3.24537 2.13361C3.14866 2.2159 3.07931 2.3257 3.04655 2.44838C3.01379 2.57107 3.01918 2.70082 3.06199 2.82036L5.54637 9.78911C5.6011 9.92441 5.6011 10.0757 5.54637 10.211L3.06199 17.1797C3.01918 17.2993 3.01379 17.429 3.04655 17.5517C3.07931 17.6744 3.14866 17.7842 3.24537 17.8665C3.34208 17.9488 3.46156 17.9997 3.58791 18.0124C3.71425 18.025 3.84147 17.999 3.95262 17.9376L17.1479 10.5469C17.2456 10.4929 17.327 10.4136 17.3837 10.3175C17.4404 10.2213 17.4704 10.1117 17.4704 10.0001C17.4704 9.88841 17.4404 9.7788 17.3837 9.68263C17.327 9.58646 17.2456 9.50723 17.1479 9.45318V9.45318Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5.625 10H10.625" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <Drawer
        title={null} // setting title to null to remove the default header
        onClose={onClose}
        open={open}
        style={{ height: '80vh' }} // set custom height
        width={700} // set custom width
        headerStyle={{ display: 'none' }} // hide the header
      >

        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">New message</h5>
          <div type="button" onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path d="M16 1.5L2 15.5" stroke="#374147" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 15.5L2 1.5" stroke="#374147" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round" />
          </svg></div>
        </div>
        <div class="bodyofinputfields">
          <input type='text' placeholder='To' className='mailinput'></input>
          <input type='text' placeholder='Subject' className='mailinput'></input>

        </div>
      </Drawer>
    </>
  )
}
export default Sendmessage