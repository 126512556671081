export const displayData=(data)=> {
    const isEmpty = data=== '' ;

    if (isEmpty) {
        return '-';
    } else {
        // Otherwise, return the data
        return data;
    }
}

export const toPascalCase = (str) => {
    if(str==null) str = "-"
    const words = str.split(/\s+/);
    const pascalCaseWords = words.map((word, index) => {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
    return pascalCaseWords.join(' ');
}