import "../Usdot.css"

const DetailModelBody = ({ company, report, vehicles, violations, closeDetails }) => {
  return (
      <div>
      <div className="headerofmodal">
          <span className="headertext">Detailed inspection report as of {report['inspection_date']}</span>
          <svg style={{cursor: "pointer"}} onClick={closeDetails} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      </div>
    <div className="coverformodel">

        <div className="twomodalinrow">
            <div className="operationalclassification">
                <div className="card-header">
                    Carrier information
                </div>
                <div className="d-flex">
                    <div className="halfside">
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Carrier name:</span>
                                <span className="tabledesc2">{company?.LEGAL_NAME}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Carrier address:</span>
                                <span className="tabledesc2">{company?.PHYSICAL_ADDRESS}</span>
                            </div>

                        </div>
                    </div>
                    <div className="halfside">
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">U.S. DOT#:</span>
                                <span className="tabledesc2">{company?.DOT_NUMBER}</span>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className="operationalclassification">
                <div className="card-header">Time/ Location</div>
                <div className="d-flex">
                    <div className="halfside">
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Report #:</span>
                                <span className="tabledesc2">{report['report#']}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">State:</span>
                                <span className="tabledesc2">{report['state']}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Start time:</span>
                                <span className="tabledesc2">{report['start_time']}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Level:</span>
                                <span className="tabledesc2">{report['level']}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Post crash inspection:</span>
                                <span className="tabledesc2">{report['POST_CRASH_INSPECTION']}</span>
                            </div>

                        </div>
                    </div>
                    <div className="halfside">
                        <div className="induvidual">
                            <div className="coverofclass">
                                <span className="tabletitle2">Report state:</span>
                                <span className="tabledesc2">{report['REPORT_STATE']}</span>
                            </div>

                        </div>
                        <div className="induvidual">
                            <span className="tabletitle2">End time:</span>
                            <span className="tabledesc2">{report['inspection_end_time']}</span>
                        </div>
                        <div className="induvidual">
                            <span className="tabletitle2">Facility:</span>
                            <span className="tabledesc2">{report['facility']}</span>
                        </div>
                        <div className="induvidual">
                            <span className="tabletitle2">Hazmat placard required:</span>
                            <span className="tabledesc2">{report['hazmat_placard_required']}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="coverofclass">
        </div>
        <div className="operationalclassification">
            <div className="card-header">
                Vehicle information
            </div>
        {vehicles.map((vehicle, index) => (
            <div className="coverofcrash borderabove">
            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">Unit:</span>
            <span className="tabledesc2">{index + 1}</span>
            </div>

            </div>

            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">Type:</span>
            <span className="tabledesc2">{vehicle['Type']}</span>
            </div>

            </div>
            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">Make:</span>
            <span className="tabledesc2">{vehicle['make']}</span>
            </div>

            </div>
            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">Plate state:</span>
            <span className="tabledesc2">{vehicle['plate_state']}</span>
            </div>

            </div>
            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">Plate number:</span>
            <span className="tabledesc2">{vehicle['plate_number']}</span>
            </div>

            </div>
            <div className="induvidual">
            <div className="coverofclass">
            <span className="tabletitle2">VIN number:</span>
            <span className="tabledesc2">{vehicle['vin_number']}</span>
            </div>

            </div>


            </div>
        ))}

        </div>
        <div className="operationalclassification">
            <div className="card-header">
                Carrier violations
            </div>
            {violations.length === 0 ? (
    <div className="alert alert-danger mt-3" role="alert">
        Nothing found
    </div>
) : (
    violations.map((violation, index) => (
        <div className="coverofcrash" key={index}>
            <div className="induvidual">
                <div className="coverofclass">
                    <span className="tabletitle2">Vio code:</span>
                    <span className="tabledesc2">{violation['violation_code']}</span>
                </div>
            </div>
            <div className="induvidual">
                <div className="coverofclass">
                    <span className="tabletitle2">Unit:</span>
                    <span className="tabledesc2">{violation['unit']}</span>
                </div>
            </div>
            <div className="induvidual">
                <div className="coverofclass">
                    <span className="tabletitle2">OOS:</span>
                    <span className="tabledesc2">{violation['oos']}</span>
                </div>
            </div>
            <div className="induvidual">
                <div className="coverofclass">
                    <span className="tabletitle2">Description:</span>
                    <span className="tabledesc2">{violation['description']}</span>
                </div>
            </div>
            <div className="induvidual">
                <div className="coverofclass">
                    <span className="tabletitle2">Basic:</span>
                    <span className="tabledesc2">{violation['basic']}</span>
                </div>
            </div>
        </div>
    ))
)}

        </div>

    </div>
      </div>
)
    ;
};


export default DetailModelBody