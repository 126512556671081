import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const BarGraph = () => {
    const [chartState] = useState({
        series: [
            { name: 'Viewing static management reports', data: [44, 55, 41, 37, 22, 43, 21, 44, 55, 41, 37, 22, 43, 21] },
            { name: 'Using personalized dashboards', data: [53, 32, 33, 52, 13, 43, 32, 53, 32, 33, 52, 13, 43, 32] },
            { name: 'Using parameterized reports', data: [12, 17, 11, 9, 15, 11, 20, 12, 17, 11, 9, 15, 11, 20] },
            { name: 'Doing simple ad hoc analysis', data: [9, 7, 5, 8, 6, 9, 4, 9, 7, 5, 8, 6, 9, 4] },
            { name: 'Doing intermediate ad hoc analysis', data: [25, 12, 19, 32, 25, 24, 10, 25, 12, 19, 32, 25, 24, 10] }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 900,
                stacked: true,
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '50%',
                    dataLabels: {
                        position: 'top',
                        enabled: false // Disable data labels
                    }
                }
            },
            stroke: {
              
            },
            xaxis: {
                categories: ["Progressive Ins Co", "SAP", "IBM", "Information Builders", "MicroStrategy", "Microsoft", "SAS", "ArcPlan", "Panorama", "Targit", "Infor", "Board", "IDS Scheer", "LogiXML"],
                labels: {
                    show: true,
                    align: 'left',
                    style: {
                        fontSize: '12px',
                        fontWeight: 600,
                        fontFamily: 'Arial, sans-serif'
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val}K`
                }
            },
            fill: {
                opacity: 1,
                colors: ['#0C94E4', '#F27077', '#AE77B1', '#6BADDF', '#9DD374']
            },
            legend: {
                position: 'right',
                offsetY: 0,
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 5
                },
                onItemClick: {
                    toggleDataSeries: true
                }
            }
        }
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '90%' }}>
                <div id="chart">
                    <ReactApexChart options={chartState.options} series={chartState.series} type="bar" height={900} />
                </div>
            </div>
            
        </div>
    );
};

export default BarGraph;
