import { create } from 'zustand';
import axios from 'axios';

const initialCheckboxes = {
    generalfreight: { checked: false, active: false },
    householdgoods: { checked: false, active: false },
    metal: { checked: false, active: false },
    motorvehicles: { checked: false, active: false },
    driveaway: { checked: false, active: false },
    logspolesbeamslumber: { checked: false, active: false },
    buildingmaterials: { checked: false, active: false },
    mobilehomes: { checked: false, active: false },
    machinerylargeobjects: { checked: false, active: false },
    freshproduce: { checked: false, active: false },
    liquidsgases: { checked: false, active: false },
    intermodalcont: { checked: false, active: false },
    oilfieldequipment: { checked: false, active: false },
    livestock: { checked: false, active: false },
    grainfeedhay: { checked: false, active: false },
    coalcoke: { checked: false, active: false },
    meat: { checked: false, active: false },
    garbagerefuse: { checked: false, active: false },
    usmail: { checked: false, active: false },
    othercustomsearch: { checked: false, active: false },
    chemicals: { checked: false, active: false },
    commoditiesdrybulk: { checked: false, active: false },
    refrigeratedfood: { checked: false, active: false },
    paperproducts: { checked: false, active: false },
    utilities: { checked: false, active: false },
    agriculturalfarmsupplies: { checked: false, active: false },
    construction: { checked: false, active: false },
    waterwell: { checked: false, active: false },
    passengers: { checked: false, active: false },
    beverages:{checked:false,active:false}
};

const labelKeys = {
    "General Freight": "generalfreight",
    "Household Goods": "householdgoods",
    "Metal: sheets, coils, rolls": "metal",
    "Motor Vehicles": "motorvehicles",
    "Drive/Tow Away": "driveaway",
    "Logs, Poles, Beams, Lumber": "logspolesbeamslumber",
    "Building Materials": "buildingmaterials",
    "Mobile Homes": "mobilehomes",
    "Machinery, Large Objects": "machinerylargeobjects",
    "Fresh Produce": "freshproduce",
    "Liquids/Gases": "liquidsgases",
    "Intermodal Cont.": "intermodalcont",
    "Passengers": "passengers",
    "Oilfield Equipment": "oilfieldequipment",
    "Livestock": "livestock",
    "Grain, Feed, Hay": "grainfeedhay",
    "Coal/Coke": "coalcoke",
    "Meat": "meat",
    "Garbage/Refuse": "garbagerefuse",
    "US Mail": "usmail",
    "Other - Custom search": "othercustomsearch",
    "Chemicals": "chemicals",
    "Commodities Dry Bulk": "commoditiesdrybulk",
    "Refrigerated Food": "refrigeratedfood",
    "Beverages": "beverages",
    "Paper Products": "paperproducts",
    "Utilities": "utilities",
    "Agricultural/Farm Supplies": "agriculturalfarmsupplies",
    "Construction": "construction",
    "Water Well": "waterwell",
};


const commodityStore = create((set) => ({
  commodity: [],
  checkboxes: initialCheckboxes, 
  labelKeys: labelKeys,
  isLoading: false,
  error: null,

  // Fetch commodities
  fetchcommoditys: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await axios.get('https://jsonplaceholder.typicode.com/commoditys');
      const commoditys = response.data;
      set({ commoditys, isLoading: false });
    } catch (error) {
      console.error('Error fetching commoditys:', error);
      set({ isLoading: false, error: 'Failed to fetch commoditys' });
    }
  },

  // Toggle checkbox state
  toggleCheckbox: (field) => {
    set((state) => ({
      checkboxes: {
        ...state?.checkboxes,
        [field]: {
          checked: !state?.checkboxes[field]?.checked,
          active: false,
        },
      },
    }));
  },

  // Toggle button state
  toggleButton: (field) => {
    set((state) => ({
      checkboxes: {
        ...state?.checkboxes,
        [field]: {
          checked: false,
          active: !state?.checkboxes[field]?.active,
        },
      },
    }));
  },

  removeFieldState: (field) => {
    set((state) => ({
      checkboxes: {
        ...state?.checkboxes,
        [field]: {
          checked: false,
          active: false,
        },
      },
    }));
  },
}));

export default commodityStore;
