import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className='notfound' style={{width:'100%'}}>
        
       <span className='sales'> Access  Denied</span> 
        <Link to='/login'><div className='btn btn-primary'>Go to Login Page</div></Link>
    </div>
  )
}

export default NotFound