import operationstore from "../../Redux/Stores/Leads/operations"
import { Modal, Slider, Select } from "antd"
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
const { Option } = Select;
const Operation = ({ isoperationopen, setIsoperationopen, usdots, setUsdots }) => {
    const {checkboxes1,toggleCheckbox,toggleButton}=operationstore()
  
    const [rangeValue, setRangeValue] = useState([200]);
    const onChange = (value) => {
        setRangeValue(value);
    };

    const handleApply = () => {
        const classs = {}
        const authorizedforhire = checkboxes1.authorizedforhire.checked ? classs['A'] = 1 : checkboxes1.authorizedforhire.active ? classs['A'] = 0 : classs['A'] = -1
        const exemptforhire = checkboxes1.exemptforhire.checked ? classs['B'] = 1 : checkboxes1.exemptforhire.active ? classs['B'] = 0 : classs['B'] = -1
        const federalgovernment = checkboxes1.federalgovernment.checked ? classs['H'] = 1 : checkboxes1.federalgovernment.active ? classs['H'] = 0 : classs['H'] = -1
        const indiantribe = checkboxes1.indiantribe.checked ? classs['J'] = 1 : checkboxes1.indiantribe.active ? classs['J'] = 0 : classs['J'] = -1
        const localgovernment = checkboxes1.localgovernment.checked ? classs['I'] = 1 : checkboxes1.localgovernment.active ? classs['I'] = 0 : classs['I'] = -1
        const migrant = checkboxes1.migrant.checked ? classs['F'] = 1 : checkboxes1.migrant.active ? classs['F'] = 0 : classs['F'] = -1
        const other = checkboxes1.other.checked ? classs['K'] = 1 : checkboxes1.other.active ? classs['K'] = 0 : classs['K'] = -1
        const privpassbusiness = checkboxes1.privpassbusiness.checked ? classs['D'] = 1 : checkboxes1.privpassbusiness.active ? classs['D'] = 0 : classs['D'] = -1
        const privpassnonbusiness = checkboxes1.privpassnonbusiness.checked ? classs['E'] = 1 : checkboxes1.privpassnonbusiness.active ? classs['E'] = 0 : classs['E'] = -1
        const privateproperty = checkboxes1.privateproperty.checked ? classs['C'] = 1 : checkboxes1.privateproperty.active ? classs['C'] = 0 : classs['C'] = -1
        const usmail = checkboxes1.usmail.checked ? classs['G'] = 1 : checkboxes1.usmail.active ? classs['G'] = 0 : classs['G'] = -1
        const interstate = checkboxes1.interstate.checked ? ` OR a.carrier_operation != 'A'` : checkboxes1.interstate.active ? ` OR a.carrier_operation = 'A'` : ``
        const intrastatehazmat = checkboxes1.intrastatehazmat.checked ? ` OR a.carrier_operation != 'B'` : checkboxes1.intrastatehazmat.active ? ` OR a.carrier_operation = 'B'` : ``
        const intrastatenonhazmat = checkboxes1.intrastatenonhazmat.checked ? ` OR a.carrier_operation != 'C'` : checkboxes1.intrastatenonhazmat.active ? ` OR a.carrier_operation = 'C'` : ``
        const classsIn = Object.keys(classs)
                            .filter(key => classs[key] === 1)
                            .map(key => `'${key}'`)
                            .join(", ");
        const classsNotIn = Object.keys(classs)
                        .filter(key => classs[key] === 0)
                        .map(key => `'${key}'`)
                        .join(", ");
        console.log(classsIn);
        console.log(classsNotIn);
        const classIn = classsIn != '' ? ` AND a.classdef in (`+classsIn+`)` : ``
        const classNotIn = classsNotIn != '' ? ` AND a.classdef not in (`+classsNotIn+`)` : ``
        let query = classIn + classNotIn + interstate + intrastatehazmat + intrastatenonhazmat
        console.log(query)
        setUsdots({query: query})
        setIsoperationopen(false)
    }

    return (
        <>
            <Modal
                wrapClassName="modalofall"
                open={isoperationopen}
                onCancel={() => setIsoperationopen(false)}
                footer={null}
                width={1104}
                closeIcon={null}
                centered={false}
            >

                <div className="headerofmodal">
                    <span className="headertext">Operations</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setIsoperationopen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="WraperofModal wrapperof">
                    <div className="cardcomponent" style={{ width: "100%" }}>
                        <div className="card-header">Operation options</div>
                        <div className="row_wrapper_COVER">
                            {['Authorized For Hire', 'Exempt For Hire', 'Federal Government', 'Indian Tribe', 'Local Government', 'Migrant', 'Other', 'Priv. Pass. (Business)', 'Priv. Pass. (Non Business)', 'Private property', 'State Government', 'U. S. Mail'].map((label) => {
                                const key = label.replace(/\s+/g, '').replace(/[().]/g, '').toLowerCase();
                                return (
                                    <div key={key} className="itemof_covers_Authority">
                                        <div className="tag_coverside">{label}</div>
                                        <div className="tag_cover">
                                            <Checkbox
                                                checked={checkboxes1[key]?.checked}
                                                name={key}
                                                onChange={() => toggleCheckbox(key)}
                                            />
                                            <button
                                                className={`toggle-btn ${checkboxes1[key]?.active ? 'active' : ''}`}
                                                onClick={() => toggleButton(key)}
                                            >
                                                {checkboxes1[key]?.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="coverfor_bottom_section">

                        <div className="coverof_Authority">
                            <div className="First_item">
                                <div className="cardcomponent">
                                    <div className="card-header">Carrier type options</div>
                                    <div className="row_wrapper_COVER">
                                        {['Interstate', 'Intrastate Hazmat', 'Intrastate Non Hazmat'].map((label) => {
                                            const key = label.replace(/\s+/g, '').replace(/[\s().]+/g, '').toLowerCase();
                                            return (
                                                <div key={key} className="itemof_covers_Authority">
                                                    <div className="tag_coverside">{label}</div>
                                                    <div className="tag_cover">
                                                        <Checkbox
                                                            checked={checkboxes1[key]?.checked}
                                                            name={key}
                                                            onChange={() => toggleCheckbox(key)}
                                                        />
                                                        <button
                                                            className={`toggle-btn ${checkboxes1[key]?.active ? 'active' : ''}`}
                                                            onClick={() => toggleButton(key)}
                                                        >
                                                            {checkboxes1[key]?.active ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            ) : null}
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                       

                       
                    </div>
                </div>


                {/* </div> */}

                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: "pointer" }} onClick={() => { setIsoperationopen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={() => handleApply()}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Operation;