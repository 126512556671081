import React, { useEffect, useState } from 'react';
import AccountNav from "../Navbar/AccountNav";
import { message, Switch } from 'antd';
import "./user.css";
import userimage from "./Images/Profile-image.png";
import axios from 'axios';
import { useNavigate , useParams } from 'react-router-dom';
import { SERVER_URL } from '../Constants/BaseUrl';

function AddCollab() {
    const [user, setUser] = useState(null);
    const { id } = useParams();
const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/collaboratorsbyid/${id}`);
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // Handle error as needed, e.g., show error message
            }
        };

        if (id) {
            fetchData();
        }
    }, [id]);
    const handleDelete = async (idtodelete) => {
        try {
            await axios.delete(`${SERVER_URL}/deletecollaborators/${idtodelete}`);
            message.success('Collaborator deleted successfully');
            navigate('/home/collaborators')
        } catch (error) {
            console.error('Error deleting collaborator:', error);
            message.error('Error deleting collaborator. Please try again.');
        }
    };
    return (
        <div className='user_main'>
            <AccountNav />
            <div className='main_content'>
                <div className='inner_account'>
                    <div className='name_email_section'>
                        <img className='profileimage' src={user && user[0]?.profileImage || userimage} alt='profile-image' />
                    </div>
                    <div className='boxinfo'>
                        {/* Name Section */}
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Name:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.name }
                                </div>
                            </div>
                        </div>

                        {/* Email Section */}
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Email:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.email }
                                </div>
                            </div>
                        </div>

                        {/* Role Section */}
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Role:</div>
                                <div className='usertitleinfo'>
                                    Collaborator
                                </div>
                            </div>
                        </div>

                        {/* Date of Birth Section */}
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Date of Birth:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.dateOfBirth }
                                </div>
                            </div>
                        </div>

                        {/* Address Section */}
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Address:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.address }
                                </div>
                            </div>
                        </div>

                        {/* Phone Section */}
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Phone:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.phone }
                                </div>
                            </div>
                        </div>

                        {/* Emergency Contact Section */}
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Emergency Contact:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.emergencyContact }
                                </div>
                            </div>
                        </div>

                        {/* Password Section */}
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Password:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.password }
                                </div>
                            </div>
                        </div>

                        {/* USDOT Number Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>USDOT Number:</div>
                                <div className='usertitleinfo'>
                                    {user && user[0]?.usdotNumber }
                                </div>
                            </div>
                        </div>

                        {/* Access Levels Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Access levels for this collaborator:</div>
                                <div>
                                    <Switch checked={user && user[0]?.accessLevels} />
                                    All
                                </div>
                            </div>
                        </div>

                        {/* Deep Drive Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Deep drive:</div>
                                <div>
                                    <Switch checked={user && user[0]?.deepDrive} />
                                    {user && user[0]?.deepDrive ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Admin / Setting Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Admin / Setting</div>
                                <div>
                                    <Switch checked={user && user[0]?.adminsetting} />
                                    {user && user[0]?.adminsetting ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Chameleon Carrier Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Chameleon Carrier:</div>
                                <div>
                                    <Switch checked={user && user[0]?.ChameleonCarrier} />
                                    {user && user[0]?.ChameleonCarrier ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Prospect Tracker Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Prospect Tracker:</div>
                                <div>
                                    <Switch checked={user && user[0]?.ProspectTracker} />
                                    {user && user[0]?.ProspectTracker ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Insurance Carrier Data Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Insurance Carrier Data:</div>
                                <div>
                                    <Switch checked={user && user[0]?.InsuranceCarrierData} />
                                    {user && user[0]?.InsuranceCarrierData ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Leads Database Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Leads Database:</div>
                                <div>
                                    <Switch checked={user && user[0]?.leads} />
                                    {user && user[0]?.leads ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                        {/* Certificate of Insurance Portal Section */}
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Certificate of Insurance Portal:</div>
                                <div>
                                    <Switch checked={user && user[0]?.certificate} />
                                    {user && user[0]?.certificate ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
  <div className='btnfordelete' onClick={()=>{handleDelete(user[0]?._id)}}>
    <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M20.25 5.25H3.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 9.75V15.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.25 9.75V15.75" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.75 5.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H9.75C9.35218 2.25 8.97064 2.40804 8.68934 2.68934C8.40804 2.97064 8.25 3.35218 8.25 3.75V5.25" stroke="#FA3434" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Delete Collaborator
    </div>

  </div>
                        {/* Additional Sections as needed */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCollab;
