import React, { useEffect, useState } from 'react';
import AccountNav from "../Navbar/AccountNav";
import userimage from "./Images/Profile-image.png";
import editimg from "./Images/editimg.png";
import passimg from "./Images/passimg.png";
import "./user.css";
import { Modal } from 'antd';
import axios from 'axios';
import UsaStatesModal from './UsaStatesModal';
import { SERVER_URL } from '../Constants/BaseUrl';

function Myinfo() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openedit, setOpenedit] = useState(false);
  //state modal
  const [visible, setVisible] = useState(false);

  // State variables for editable fields
  const [editName, setEditName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editAccessLevel, setEditAccessLevel] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editPhone, setEditPhone] = useState("");
  // Number of Allowed states
  const [statesAllowed, setStatesAllowed] = useState(null)
  // Function to handle modal open and load current user data
  const handleOpenEdit = () => {
    setEditName(user?.billingAddress.firstName || "");
    setEditEmail(user?.email || "");
    setEditPassword(user?.password || "");
    setEditAccessLevel("Admin"); // Assuming default value
    setEditAddress(user?.billingAddress.address || "");
    setEditPhone(user?.phoneNumber || "");
    setOpenedit(true);
  };

  // Function to handle save changes
  const handleSaveChanges = async () => {
    try {
      const updatedUserData = {
        fullName: editName,
        email: editEmail,
        password: editPassword,
        accessLevel: editAccessLevel,
        billingAddress: editAddress,
        phoneNumber: editPhone,
      };

      const response = await axios.put(`${SERVER_URL}/updateusers/${user._id}`, updatedUserData);
      if (response.status === 200) {
        // Update user state with new data
        setUser(response.data.data);
        setOpenedit(false); // Close modal after successful update
      } else {
        console.error("Error updating user data:", response.data.message);
        // Handle error
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle error
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    const fetchUserData = async () => {
      try {
        if (!userId) {
          console.error('User ID not found in localStorage');
          setLoading(false);
          return;
        }

        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        const userData = userResponse.data.data;

        setUser(userData);
        setLoading(false); // Set loading to false after successful data fetch

        if (userData.purchasedcategory === 'Starter' || userData.purchasedcategory === 'Business') {
          setStatesAllowed(userData.purchasedcategory === 'Starter' ? 1 : 3);

          const stateInfoResponse = await axios.get(`${SERVER_URL}/userdata/${userId}`);
          const stateInfoData = stateInfoResponse.data.user.states;

          // Check if states is an array
          if (Array.isArray(stateInfoData)) {
            if (stateInfoData.length === 0) {
              setVisible(true);
            }
          } else {
            console.log('states is not an array or is undefined');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false); // Set loading to false on error
        // Handle additional error logic if needed
      }
    };

    fetchUserData();
  }, []);
  // Empty dependency array ensures this effect runs only once
  const [passty, setpassty] = useState('password')
  const handleinputytpe = () => {
    if (passty === 'password') {
      setpassty('text')
    } else {
      setpassty('password')

    }
  }

  return (
    <div className='user_main'>
      <div>
        <AccountNav />
      </div>
      <div className='main_content'>
        <div className='inner_account'>
          {/* user name email profile image section */}
          <div className='name_email_section'>
  <div className='profile_image'>
    <div className='nameletter'>
      {user?.billingAddress.firstName?.charAt(0).toUpperCase() || ''}
    </div>
  </div>
  <div className='namesection'>
    <div className='username'>{user?.billingAddress.firstName}</div>
    <div className='useremail'>{user?.email}</div>
  </div>
</div>


          {/* user all information  */}
          <div className='boxinfo'>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Name</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.billingAddress.firstName} </div>
              </div>
              <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Email:</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.email}</div>
              </div>
              <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Password:</div>
                <div className='usertitleinfo'><input type={passty} value={loading ? 'Loading...' : user?.password} disabled /></div>
              </div>
              <div className='d-flex gap-3'>
                <img className='editimage' src={passimg} alt='edit image' onClick={handleinputytpe}></img>
                <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
              </div>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Access Level: </div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : 'Admin'}</div>
              </div>
              <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Address:</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.billingAddress.address}</div>
              </div>
              <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Phone:</div>
                <div className='usertitleinfo'>{loading ? 'Loading...' : user?.phoneNumber}</div>
              </div>
              <img className='editimage' src={editimg} onClick={handleOpenEdit} alt='edit image'></img>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openedit}
        onCancel={() => setOpenedit(false)}
        footer={null}
        closeIcon={null}
        width={600}
      >
        <div className="headerofmodal">
          <span className="headertext">Edit </span>
          <svg style={{ cursor: "pointer" }} onClick={() => { setOpenedit(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="bodyofmodal">
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Name</div>
              <input
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                placeholder="Ex. John Doe"
                className="usdotinput"
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Email</div>
              <input
                type="text"
                value={editEmail}
                onChange={(e) => setEditEmail(e.target.value)}
                placeholder="Ex. john@john.com"
                className="usdotinput"
              />
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Password</div>
              <input
                type="password"
                value={editPassword}
                onChange={(e) => setEditPassword(e.target.value)}
                placeholder="Ex. 9@jahk_$%*(@"
                className="usdotinput"
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Access Level</div>
              <select
                value={editAccessLevel}
                onChange={(e) => setEditAccessLevel(e.target.value)}
                className="usdotinput"
              >
                <option value="Admin">Admin</option>
                <option value="Producer">Producer</option>
                <option value="CSR">CSR</option>
              </select>
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Address</div>
              <input
                type="text"
                value={editAddress}
                onChange={(e) => setEditAddress(e.target.value)}
                placeholder="Ex. Main St.400"
                className="usdotinput"
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Phone</div>
              <input
                type="tel"
                value={editPhone}
                onChange={(e) => setEditPhone(e.target.value)}
                placeholder="Ex. 2000"
                className="usdotinput"
              />
            </div>
          </div>
          <div className="footerofmodal">
            <span className="cancelbutton" onClick={() => { setOpenedit(false) }}>Cancel</span>
            <div className="submitbutton" onClick={handleSaveChanges}>
              Save
            </div>
          </div>
        </div>
      </Modal>
      <UsaStatesModal visible={visible} setVisible={setVisible} statesAllowed={statesAllowed} subscriptionid={user?._id} />
    </div>
  );
}

export default Myinfo;
