import './App.css';
import { Main } from './Routes/Main';
function App() {
  return (
    <div className="App">
        <Main />
    </div>
  );
}

export default App;
