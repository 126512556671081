import React from 'react'
import { Modal } from 'antd';
import { Select } from 'antd';
import { displayData, toPascalCase } from "../../Utility/Services"
import { Hourglass } from 'react-loader-spinner';
export const ComparisionModel = ({ data,
    similar_dots,
    crash,
    crashReport,
    legalName,
    defaultValue,
    companyRepresentative,
    companyCompRepresentative,
    mcMxFfNumber,
    physicalAddress,
    physicalComAddress,
    phone,
    mailingAddress,
    entityType,
    isModalOpened,
    handleOk,
    handleCancel,
    handleChange,
    mcs150Mileage,
    inspections,
    compareInspections,
    dotNumber,
    classifications,
    Option,
    phoneCom,
    email,
    powerUnits,
    dunsNumber,
    dbaName,
    operationClassification,
    operations,
    carrierOperation,
    mcs150Date,
    Cargo,
    addDate,
    cargoCarried,
    openDetail,
    compCrash,
    compCrashReport,
    drivers,
    loaderins
     }) => {
    return (
        <>
            <Modal
                wrapClassName="modalofall"
                closeIcon={null}
                open={isModalOpened}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1100}
                centered={false}
                style={{ margin: "0 auto" }}
                footer={null}

            >
   
                <div className="headerofmodal">
                    <span className="headertext">Comparison of companies</span>
                    <svg style={{ cursor: "pointer" }} onClick={handleCancel} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
              
                {/* Modal Body */}
                {
    loaderins ? ( <div style={{background:"white",padding:"50px",display:"flex",justifyContent:"center"}}>
        <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#306cce', '#72a1ed']}
        />
    </div>):( <div className="parentofmodal">
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="titleofcontent">
                                Original Carrier: </div>
                            <div className="usdotplace">
                                USDOT # {data?.DOT_NUMBER}
                            </div>

                        </div>
                        <div className="leftsideofmodal">
                            <div className="titleofcontent">
                                Chameleon carrier: </div>

                            <Select popupClassName="dropclass" onChange={handleChange} defaultValue={defaultValue} style={{ width: 516, height: 56 , border:"1px solid grey", borderRadius:"8px"}} >
                                {/* <List
                                        height={200}
                                        itemCount={similar_dots.length}
                                        itemSize={30}
                                        width={300}
                                    >
                                        {Row}
                                    </List> */}

                                {similar_dots.map((option, index) => (
                                    <Option value={option.dot_numbers_relavent}>USDOT # {option.dot_numbers_relavent}</Option>
                                ))}
                            </Select>

                        </div>
                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="cardcomponent">
                                <div className="card-header">
                                    Original Carrier: {toPascalCase(displayData(data?.LEGAL_NAME))}
                                </div>
                                <div className="row_wrapper">
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">Entity type:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.ENTITY_TYPE))}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Operating status:</div>
                                            <div className="data_desc  ">Authorized for property</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Legal name:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.LEGAL_NAME))} </div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Add date: </div>
                                            <div className="data_desc  ">{displayData(data?.ADD_DATE)}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Company representative:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.COMPANY_REPRESENTATIVE))}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title" style={{ wordWrap: "break-word", maxWidth: "300px" }}>Physical address:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.PHYSICAL_ADDRESS))}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">Phone:</div>
                                            <div className="data_desc  ">{displayData(data?.PHONE)}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title" style={{ wordWrap: "break-word", maxWidth: "300px" }}>Mailing address:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.mailing_address))}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title" style={{ wordWrap: "break-word", maxWidth: "300px" }}>Email:</div>
                                            <div className="data_desc  ">{toPascalCase(displayData(data?.EMAIL))}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">MC/MX/FF number(s):</div>
                                            <div className="data_desc  ">{displayData(data?.mc_mx_ff_number)}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">Drivers:</div>
                                            <div className="data_desc  ">{displayData(data?.drivers)}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Power units:</div>
                                            <div className="data_desc  ">{displayData(data?.power_units)}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">DUNS number: </div>
                                            <div className="data_desc  ">{displayData(data?.DUNS_NUMBER)}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">MCS-150 form date:</div>
                                            <div className="data_desc  ">{displayData(data?.MCS_150_DATE)}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">MCS-150 mileage (Year):</div>
                                            <div className="data_desc  ">{displayData(data?.mcs_150_mileage)}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">DBA Name:</div>
                                            <div className="data_desc  ">{displayData(data?.DBA_NAME)}</div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="leftsideofmodal">
                            <div className="cardcomponent">
                                <div className="card-header">
                                    Chameleon Carrier #1: {legalName}
                                </div>
                                <div className="row_wrapper">
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">Entity type:</div>
                                            <div className="data_desc  ">{entityType}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Operating status:</div>
                                            <div className="data_desc  ">Authorized for property</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Legal name:</div>
                                            <div className="data_desc  ">{legalName}      </div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Add date: </div>
                                            <div className="data_desc  ">{addDate}</div>
                                        </div>


                                    </div>


                                    <div className="row_content">
                                        {companyRepresentative === companyCompRepresentative ?

                                            <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Company representative:</div>

                                                <div className="data_desc d-flex text-danger   align-item-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                                        <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                                    </svg>
                                                    <span className="mx-1">{companyRepresentative}</span></div>
                                            </div> :
                                            <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Company representative:</div>

                                                <div className="data_desc    align-item-center">
                                                    <span className="mx-1">{companyRepresentative}</span></div>
                                            </div>
                                        }

                                        {
                                            physicalAddress === physicalComAddress ?
                                                <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                    <div className="data_title">Physical address:</div>
                                                    <div className="data_desc d-flex text-danger   align-item-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 16 16" fill="none">
                                                            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                                            <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                                        </svg>
                                                        <div className="mx-1 ">{physicalAddress}</div>
                                                    </div>
                                                </div> :

                                                <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                    <div className="data_title">Physical address:</div>

                                                    <div className="data_desc  data_desc    align-item-center ">
                                                        <span className="mx-1">
                                                            {physicalAddress}
                                                        </span>
                                                    </div>
                                                </div>

                                        }

                                        {/* d-flex text-danger */}
                                    </div>

                                    {phone === phoneCom ?
                                        <div className="row_content">
                                            <div className="contentpop">
                                                <div className="data_title">Phone:</div>
                                                <div className="data_desc d-flex text-danger   align-item-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                                        <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                                    </svg>
                                                    <span className="mx-1">{phone}</span></div>
                                            </div>
                                            <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Mailing address:</div>
                                                <div className="data_desc  ">{mailingAddress}</div>
                                            </div>


                                        </div>
                                        :
                                        <div className="row_content">
                                            <div className="contentpop">
                                                <div className="data_title">Phone:</div>
                                                <div className="data_desc    align-item-center">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#FE2727" stroke-width="1.5" stroke-miterlimit="10" />
                                                    <path d="M8 5V8.5" stroke="#FE2727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#FE2727" />
                                                </svg> */}
                                                    <span className="mx-1">{phone}</span></div>
                                            </div>
                                            <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                                <div className="data_title">Mailing address:</div>
                                                <div className="data_desc  ">{mailingAddress}</div>
                                            </div>


                                        </div>
                                    }

                                    <div className="row_content">
                                        <div className="contentpop" style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                                            <div className="data_title">Email:</div>
                                            <div className="data_desc  ">{email}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">MC/MX/FF number(s):</div>
                                            <div className="data_desc  ">{mcMxFfNumber}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">Drivers:</div>
                                            <div className="data_desc  ">{drivers}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">Power units:</div>
                                            <div className="data_desc  ">{powerUnits}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">DUNS number: </div>
                                            <div className="data_desc  ">{dunsNumber}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">MCS-150 form date:</div>
                                            <div className="data_desc  ">{mcs150Date}</div>
                                        </div>


                                    </div>
                                    <div className="row_content">
                                        <div className="contentpop">
                                            <div className="data_title">MCS-150 mileage (Year):</div>
                                            <div className="data_desc  ">{mcs150Mileage}</div>
                                        </div>
                                        <div className="contentpop">
                                            <div className="data_title">DBA Name:</div>
                                            <div className="data_desc  ">{dbaName}</div>
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Operation Classification
                                </div>
                                <div className="classificationwrapper">
                                    {classifications.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={data.OPERATION_CLASSIFICATION.toLowerCase().includes(option.value.toLowerCase()) ? 'classification activating' : 'classification'}
                                            // onClick={() => handleClick(index)}
                                            >

                                                {data.OPERATION_CLASSIFICATION.toLowerCase().includes(option.value.toLowerCase()) && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option?.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Operation Classification
                                </div>
                                <div className="classificationwrapper">
                                    {classifications.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={operationClassification.toLowerCase().includes(option.value.toLowerCase()) ? 'classification activating' : 'classification'}
                                            // onClick={() => handleClick(index)}
                                            >

                                                {operationClassification.toLowerCase().includes(option.value.toLowerCase()) && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Carrier Operation
                                </div>
                                <div className="classificationwrapper">
                                    {operations.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={option.name.toLowerCase() == data.CARRIER_OPERATION.toLowerCase() ? 'classification activating' : 'classification'}
                                            // onClick={() => handleClick(index)}
                                            >

                                                {option.name.toLowerCase() == data.CARRIER_OPERATION.toLowerCase() && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Carrier Operation
                                </div>
                                <div className="classificationwrapper">
                                    {operations.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={option.name.toLowerCase() == carrierOperation.toLowerCase() ? 'classification activating' : 'classification'}
                                            // onClick={() => handleClick(index)}
                                            >

                                                {option.name.toLowerCase() == carrierOperation.toLowerCase() && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Cargo Carried
                                </div>
                                <div className="classificationwrapper">
                                    {Cargo.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={data.CARGO_CARRIED.toLowerCase().includes(option.name.toLowerCase()) ? 'classification activating' : 'classification'}
                                            // onClick={() => handlecargo(index)}
                                            >

                                                {data.CARGO_CARRIED.toLowerCase().includes(option.name.toLowerCase()) && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Cargo Carried
                                </div>
                                <div className="classificationwrapper">
                                    {Cargo.map((option, index) => (
                                        <div key={index}>
                                            <div
                                                className={cargoCarried.toLowerCase().includes(option.name.toLowerCase()) ? 'classification activating' : 'classification'}
                                            // onClick={() => handlecargo(index)}
                                            >

                                                {cargoCarried.toLowerCase().includes(option.name.toLowerCase()) && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M13.5 4.5L6.5 11.5L3 8"
                                                            stroke="#2FB95E"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {option.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Inspections/ Vehicle details
                                </div>
                                <div className="classificationwrapper2">
                                    <div className="inboxcontain">
                                        US inspection results for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>
                                <div className="maincoverage">
                                    <div className="inboxcontain borderright">Total inspections: <strong>{inspections.length}</strong> </div>
                                    <div className="inboxcontain">Total IEP inspections: <strong>0</strong> </div>

                                </div>
                                {crash?.map((crashData) => (

                                    <table style={{ width: "90%", marginLeft: "22px" }}>
                                        <tr className="headeroftables mt-2">
                                            <td>Inspection Type</td>
                                            <td>Vehicle</td>
                                            <td>Driver</td>
                                            <td>Hazmat</td>
                                            <td>IEP</td>

                                        </tr>
                                        <tr className="mt-2">
                                            <td className="tablesidehead " >Inspection </td>
                                            <td className="descoftabler">{crashData?.vehicle}</td>
                                            <td className="descoftabler">{crashData?.inspections_drivers}</td>
                                            <td className="descoftabler">{crashData?.inspection_hazmat}</td>
                                            <td className="descoftabler">0</td>

                                        </tr>

                                        <tr className="mt-2">
                                            <td className="tablesidehead">Out of Service</td>
                                            <td className="descoftabler">{crashData?.out_of_service_vehicles}</td>
                                            <td className="descoftabler">{crashData?.out_of_srevice_driver}</td>
                                            <td className="descoftabler">0</td>
                                            <td className="descoftabler">0</td>

                                        </tr>
                                        <tr className="mt-2">
                                            <td className="tablesidehead">Out of Service %</td>
                                            <td className="descoftabler">{crashData?.percentageOOS_Driver}</td>
                                            <td className="descoftabler">{crashData?.percentageOOS_vehicle}</td>
                                            <td className="descoftabler">0%</td>
                                            <td className="descoftabler">0</td>

                                        </tr>
                                        <tr className="mt-2">
                                            <td className="tablesidehead">Nat’l Average %<br></br>
                                                (as of 01/26/2024)</td>
                                            <td className="descoftabler">22.26%</td>
                                            <td className="descoftabler">6.67%</td>
                                            <td className="descoftabler">4.44%  </td>
                                            <td className="descoftabler">N/A</td>

                                        </tr>
                                    </table>
                                ))}
                                <br></br><br></br>
                            </div>
                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Inspections/ Vehicle details
                                </div>
                                <div className="classificationwrapper2">
                                    <div className="inboxcontain">
                                        US inspection results for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>
                                <div className="maincoverage">
                                    <div className="inboxcontain borderright">Total inspections: <strong>{compareInspections.length}</strong> </div>
                                    <div className="inboxcontain">Total IEP inspections: <strong>0</strong> </div>

                                </div>
                                {
                                    compCrash?.map((compCrashData) => (


                                        <table style={{ width: "90%", marginLeft: "22px" }}>
                                            <tr className="headeroftables mt-2">
                                                <td>Inspection Type</td>
                                                <td>Vehicle</td>
                                                <td>Driver</td>
                                                <td>Hazmat</td>
                                                <td>IEP</td>

                                            </tr>
                                            <tr className="mt-2">
                                                <td className="tablesidehead">Inspection </td>
                                                <td className="descoftabler">{compCrashData?.vehicle}</td>
                                                <td className="descoftabler">{compCrashData?.inspections_drivers}</td>
                                                <td className="descoftabler">{compCrashData?.inspection_hazmat}</td>
                                                <td className="descoftabler">0</td>

                                            </tr>
                                            <tr className="mt-2">
                                                <td className="tablesidehead">Out of Service</td>
                                                <td className="descoftabler">{compCrashData?.out_of_service_vehicles}</td>
                                                <td className="descoftabler">{compCrashData?.out_of_srevice_driver}</td>
                                                <td className="descoftabler">0</td>
                                                <td className="descoftabler">0</td>

                                            </tr>
                                            <tr className="mt-2">
                                                <td className="tablesidehead">Out of Service %</td>
                                                <td className="descoftabler">{compCrashData?.percentageOOS_Driver}</td>
                                                <td className="descoftabler">{compCrashData?.percentageOOS_vehicle}</td>
                                                <td className="descoftabler">0%</td>
                                                <td className="descoftabler">0</td>

                                            </tr>
                                            <tr className="mt-2">
                                                <td className="tablesidehead">Nat’l Average %<br></br>
                                                    (as of 01/26/2024)</td>
                                                <td className="descoftabler">22.26%</td>
                                                <td className="descoftabler">6.67%</td>
                                                <td className="descoftabler">4.44%  </td>
                                                <td className="descoftabler">N/A</td>

                                            </tr>
                                        </table>


                                    ))


                                }
                                <br></br><br></br>
                            </div>
                        </div>
                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Inspections/ Vehicle details
                                </div>
                                <div className="classificationwrapper2">
                                    <div className="inboxcontain">
                                        US inspection results for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>
                                <div className="padder">
                                    {inspections.filter(item => item.dot_number === data.DOT_NUMBER).map((inspection, index) => (
                                        <div className="headeroftableunder" style={{ width: "100%" }} key={index}>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle" style={{ width: "100%" }}>Date:</span>
                                                <span className="tabledesc" style={{ width: "100%" }}>{inspection.inspection_date}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle" style={{ width: "100%" }}>Plate Number</span>
                                                <span className="tabledesc" style={{ width: "100%" }}>{inspection.plate_number}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle" style={{ width: "100%" }}>Plate State:</span>
                                                <span className="tabledesc" style={{ width: "100%" }}>{inspection.plate_state}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle" style={{ width: "100%" }}>Ins state</span>
                                                <span className="tabledesc" style={{ width: "100%" }}>{inspection.ins_state}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="detailtext" style={{ width: "100%" }} onClick={() => {
                                                    openDetail(data, inspection['INSPECTION_ID'])
                                                }}>View Detail</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Inspections/ Vehicle details
                                </div>
                                <div className="classificationwrapper2">
                                    <div className="inboxcontain">
                                        US inspection results for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>
                                <div className="padder">
                                    {compareInspections.filter(item => item.dot_number === dotNumber).map((inspection, index) => (
                                        <div className="headeroftableunder" key={index} >
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle">Date:</span>
                                                <span className="tabledesc">{displayData(inspection.inspection_date)}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle">Plate Number</span>
                                                <span className="tabledesc">{displayData(inspection.plate_number)}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle">Plate State:</span>
                                                <span className="tabledesc">{displayData(inspection.plate_state)}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="tabletitle">Ins state</span>
                                                <span className="tabledesc">{displayData(inspection.ins_state)}</span>
                                            </div>
                                            <div className="contentareafortableunder">
                                                <span className="detailtext" onClick={() => {
                                                    openDetail({
                                                        DOT_NUMBER: dotNumber,
                                                        PHYSICAL_ADDRESS: physicalAddress,
                                                        LEGAL_NAME: legalName
                                                    }, inspection['INSPECTION_ID'])
                                                }}>View Detail</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            {/* <div className="operationalclassification">
                                    <div className="card-header">
                                        Crashes in US
                                    </div>
                                    <div className="classificationwrapper2">

                                        <div className="inboxcontain " >
                                            Crashes reported to FMCSA by states for 24 months prior to:<strong> 04/26/2024</strong>
                                        </div>
                                    </div>
                                    {
                                        compCrash.map((compCrash) => (

                                            <div className="noted">
                                                <div className="contentareafortable">
                                                    <span className="tabletitle2">Type:</span>
                                                    <span className="tabledesc2">Crashes</span>
                                                </div>
                                                <div className="contentareafortable">
                                                    <span className="tabletitle2">Fatal:</span>
                                                    <span className="tabledesc2">0</span>
                                                </div>
                                                <div className="contentareafortable">
                                                    <span className="tabletitle2">Injury:</span>
                                                    <span className="tabledesc2">1</span>
                                                </div>
                                                <div className="contentareafortable">
                                                    <span className="tabletitle2">Tow:</span>
                                                    <span className="tabledesc2">1</span>
                                                </div>
                                                <div className="contentareafortable">
                                                    <span className="tabletitle2">Total:</span>
                                                    <span className="tabledesc2">{displayData(compCrash?.outofservice_total)}</span>
                                                </div>
                                            </div>

                                        ))
                                    }
                                    <div className="importantnote">
                                        <strong>Note:</strong> Crashes listed represent a motor carrier involvement in reportable crashes, without any determination as to responsibility
                                    </div>
                                </div> */}
                        </div>
                    </div>
                    <div className="modalcontent">
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Crashes in US
                                </div>
                                <div className="classificationwrapper2">

                                    <div className="inboxcontain " >
                                        Crashes reported to FMCSA by states for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>

                                <div className="noted">
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Type:</span>
                                        <span className="tabledesc2">Crashes</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Fatal:</span>
                                        <span className="tabledesc2">{crashReport.length == 0 ? 0 : crashReport[0]['fatal']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Injury:</span>
                                        <span className="tabledesc2">{crashReport.length == 0 ? 0 : crashReport[0]['injury']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Tow:</span>
                                        <span className="tabledesc2">{crashReport.length == 0 ? 0 : crashReport[0]['tow']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Total:</span>
                                        <span className="tabledesc2">{crashReport.length == 0 ? 0 : crashReport[0]['total']}</span>
                                    </div>
                                </div>

                                <div className="importantnote">
                                    <strong>Note:</strong> Crashes listed represent a motor carrier involvement in reportable crashes, without any determination as to responsibility
                                </div>
                            </div>
                        </div>
                        <div className="leftsideofmodal">
                            <div className="operationalclassification">
                                <div className="card-header">
                                    Crashes in US
                                </div>
                                <div className="classificationwrapper2">

                                    <div className="inboxcontain " >
                                        Crashes reported to FMCSA by states for 24 months prior to:<strong> 04/26/2024</strong>
                                    </div>
                                </div>

                                <div className="noted">
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Type:</span>
                                        <span className="tabledesc2">Crashes</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Fatal:</span>
                                        <span className="tabledesc2">{compCrashReport.length == 0 ? 0 : compCrashReport[0]['fatal']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Injury:</span>
                                        <span className="tabledesc2">{compCrashReport.length == 0 ? 0 : compCrashReport[0]['injury']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Tow:</span>
                                        <span className="tabledesc2">{compCrashReport.length == 0 ? 0 : compCrashReport[0]['tow']}</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Total:</span>
                                        <span className="tabledesc2">{compCrashReport.length == 0 ? 0 : compCrashReport[0]['total']}</span>
                                    </div>
                                </div>

                                <div className="importantnote">
                                    <strong>Note:</strong> Crashes listed represent a motor carrier involvement in reportable crashes, without any determination as to responsibility
                                </div>
                            </div>
                        </div>
                    </div>

                </div>)
}
               

            </Modal>

        </>
    )
}