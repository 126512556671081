import React, { useEffect, useState } from 'react';
import AccountNav from "../Navbar/AccountNav";
import { Input, Switch, Button, DatePicker, message, Avatar } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import "./user.css";
import moment from 'moment';
import userimage from "./Images/Profile-image.png";
import axios from 'axios';
import { SERVER_URL } from '../Constants/BaseUrl';
function AddCollab() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        function: '',
        dateOfBirth: null,
        address: '',
        phone: '',
        emergencyContact: '',
        password: '',
        accessLevels: false,
        deepDrive: false,
        ChameleonCarrier: false,
        usdotNumber: '',
        ProspectTracker: false,
        leads: false,
        profileImage: null,
        adminsetting: false,
        InsuranceCarrierData: false,
        certificate: false
    });
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            setUser(userId);
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date, dateString) => {
        setFormData({ ...formData, dateOfBirth: dateString });
    };

    const handleSwitchChange = (name, checked) => {
        if (name === 'accessLevels') {
            setFormData({
                ...formData,
                accessLevels: checked,
                deepDrive: checked,
                ChameleonCarrier: checked,
                ProspectTracker: checked,
                leads: checked,
                adminsetting: checked,
                InsuranceCarrierData: checked,
                certificate: checked
            });
        } else {
            setFormData({ ...formData, [name]: checked });
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (allowedTypes.includes(file.type)) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('upload_preset', 'united eldt');
                try {
                    const response = await fetch('https://api.cloudinary.com/v1_1/dgmzv5drk/image/upload', {
                        method: 'POST',
                        body: formData,
                    });
                    if (!response.ok) {
                        throw new Error('Image upload failed');
                    }
                    const data = await response.json();
                    setFormData({ ...formData, profileImage: data.secure_url });
                    message.success('Image uploaded successfully.');
                } catch (error) {
                    console.error('Error uploading image:', error);
                    message.error('Error uploading image. Please try again.');
                } finally {
                    setLoading(false);
                }
            } else {
                message.error('Invalid file type. Please choose a valid image file.');
                setLoading(false);
            }
        }
    };


    const handleSubmit = async () => {
        try {
            // Add subscriptionId to formData
            const updatedFormData = { ...formData, subscriptionId: user };
    
            const response = await axios.post(`${SERVER_URL}/saveUserData`, updatedFormData);
    
            if (response.status === 200) {
                message.success('Data saved successfully.');
            } else {
                throw new Error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            message.error('Error saving data. Please try again.');
        }
    };
    
    

    return (
        <div className='user_main'>
            <AccountNav />
            <div className='main_content'>
                <div className='inner_account'>
                    <div className='name_email_section'>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            id="upload-input"
                            onChange={handleFileChange}
                        />
<Avatar size={50}/>                        <label htmlFor="upload-input">
                            <Button icon={<UploadOutlined />}>Upload Image</Button>
                        </label>
                    </div>
                    <div className='boxinfo'>
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Name:</div>
                                <div className='usertitleinfo'>
                                    <Input name="name" value={formData.name} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Email:</div>
                                <div className='usertitleinfo'>
                                    <Input name="email" style={{ width: '400px', background: '#FFF' }} value={formData.email} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Role:</div>
                                <div className='usertitleinfo'>
                                    <Input name="function" value={formData.function} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Date of Birth:</div>
                                <div className='usertitleinfo'>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        value={formData.dateOfBirth ? moment(formData.dateOfBirth, 'YYYY-MM-DD') : null}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Address:</div>
                                <div className='usertitleinfo'>
                                    <Input name="address" style={{ width: '400px' }} value={formData.address} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Phone:</div>
                                <div className='usertitleinfo'>
                                    <Input name="phone" style={{ width: '400px', background: '#FFF' }} value={formData.phone} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Emergency Contact:</div>
                                <div className='usertitleinfo'>
                                    <Input name="emergencyContact" style={{ width: '400px' }} value={formData.emergencyContact} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_white'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>Password:</div>
                                <div className='usertitleinfo'>
                                    <Input name="password" style={{ width: '400px', background: '#FFF' }} value={formData.password} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex gap-3'>
                                <div className='usertitle'>USDOT Number:</div>
                                <div className='usertitleinfo'>
                                    <Input name="usdotNumber" style={{ width: '400px' }} value={formData.usdotNumber} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Access levels for this collaborator:</div>
                                <div style={{marginRight:"38px"}}>
                                    <Switch checked={formData.accessLevels} onChange={(checked) => handleSwitchChange('accessLevels', checked)} /> All 
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Deep drive:</div>
                                <div>
                                    <Switch checked={formData.deepDrive} onChange={(checked) => handleSwitchChange('deepDrive', checked)} /> {formData.deepDrive ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Admin / Setting</div>
                                <div>
                                    <Switch checked={formData.adminsetting} onChange={(checked) => handleSwitchChange('adminsetting', checked)} /> {formData.adminsetting ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Chameleon Carrier:</div>
                                <div>
                                    <Switch checked={formData.ChameleonCarrier} onChange={(checked) => handleSwitchChange('ChameleonCarrier', checked)} /> {formData.ChameleonCarrier ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Prospect Tracker:</div>
                                <div>
                                    <Switch checked={formData.ProspectTracker} onChange={(checked) => handleSwitchChange('ProspectTracker', checked)} /> {formData.ProspectTracker ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Insurance Carrier Data:</div>
                                <div>
                                    <Switch checked={formData.InsuranceCarrierData} onChange={(checked) => handleSwitchChange('InsuranceCarrierData', checked)} /> {formData.InsuranceCarrierData ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Leads Database:</div>
                                <div>
                                    <Switch checked={formData.leads} onChange={(checked) => handleSwitchChange('leads', checked)} /> {formData.leads ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                        <div className='userstrap_grey2'>
                            <div className='d-flex justify-content-between' style={{ width: '100%' }}>
                                <div className='usertitle'>Certificate of Insurance Portal:</div>
                                <div>
                                    <Switch checked={formData.certificate} onChange={(checked) => handleSwitchChange('certificate', checked)} /> {formData.certificate ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button type='primary' onClick={handleSubmit} loading={loading}>Save</Button>
                </div>
            </div>
        </div>
    );
}

export default AddCollab;
