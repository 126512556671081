import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { SERVER_URL } from '../Constants/BaseUrl';

const Table = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setUser(userId);
    }
  }, []);
  const fetchCollaborators = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/collaborators/${user}`);
      setCollaborators(response.data);
    } catch (error) {
      console.error('Error fetching collaborators:', error);
    }
  };
  useEffect(() => {
 

    if (user) {
      fetchCollaborators();
    }
  }, [user]);

  const handleDropdownClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  const handleDelete = async (idtodelete) => {
    try {
        await axios.delete(`${SERVER_URL}/deletecollaborators/${idtodelete}`);
        message.success('Collaborator deleted successfully');
        fetchCollaborators()
    } catch (error) {
        console.error('Error deleting collaborator:', error);
        message.error('Error deleting collaborator. Please try again.');
    }
};
  return (
    <div className='mt-5'>
      <table className="custom-table">
        <thead>
          <tr>
            <th className="custom-th">Full Name</th>
            <th className="custom-th">Email</th>
            <th className="custom-th">Created Date</th>
            <th className="custom-th">Details</th>
          </tr>
        </thead>
        <tbody>
          {collaborators.map((collaborator, index) => (
            <tr key={index}>
              <td className="custom-td">{collaborator.name}</td>
              <td className="custom-td">{collaborator.email}</td>
              <td className="custom-td">{collaborator.createdAt}</td>
              <td className="custom-td">
                <div className="dropdown" onClick={() => handleDropdownClick(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M16.167 17.6665C16.9954 17.6665 17.667 16.9949 17.667 16.1665C17.667 15.3381 16.9954 14.6665 16.167 14.6665C15.3386 14.6665 14.667 15.3381 14.667 16.1665C14.667 16.9949 15.3386 17.6665 16.167 17.6665Z"
                      fill="#0A0A0A"
                    />
                    <path
                      d="M16.167 9.6665C16.9954 9.6665 17.667 8.99493 17.667 8.1665C17.667 7.33808 16.9954 6.6665 16.167 6.6665C15.3386 6.6665 14.667 7.33808 14.667 8.1665C14.667 8.99493 15.3386 9.6665 16.167 9.6665Z"
                      fill="#0A0A0A"
                    />
                    <path
                      d="M16.167 25.6665C16.9954 25.6665 17.667 24.9949 17.667 24.1665C17.667 23.3381 16.9954 22.6665 16.167 22.6665C15.3386 22.6665 14.667 23.3381 14.667 24.1665C14.667 24.9949 15.3386 25.6665 16.167 25.6665Z"
                      fill="#0A0A0A"
                    />
                  </svg>
                </div>
                {selectedRow === index && (
                  <div className="dropdown-content">
               <Link to={`/home/collaborator/${collaborator._id}`}>     <div> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.41797 20.2501C5.75784 18.7588 6.59412 17.4272 7.78981 16.4733C8.98549 15.5195 10.4697 15 11.9992 15C13.5288 15 15.0129 15.5195 16.2086 16.4733C17.4043 17.4272 18.2406 18.7588 18.5805 20.2501" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View Full Profile</div></Link>
                    <div className='mt-3' onClick={()=>{handleDelete(collaborator._id)}}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M20.25 5.25H3.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 9.75V15.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.25 9.75V15.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.75 5.25V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.75 5.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H9.75C9.35218 2.25 8.97064 2.40804 8.68934 2.68934C8.40804 2.97064 8.25 3.35218 8.25 3.75V5.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Delete Profile</div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
