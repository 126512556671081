import  commodityStore from "../../Redux/Stores/Leads/commodities"
import { Modal } from "antd";
import Checkbox from '@mui/material/Checkbox';
import { useState } from "react";
const Commodities = ({ commoditiesopen, setCommoditiesopen, setUsdots }) => {
    const {checkboxes,toggleCheckbox,toggleButton,labelKeys}=commodityStore()

    const handleApply = () => {
        const chemicals = checkboxes.chemicals.checked ? ` OR (a.Carries_CHEM <> '')` : checkboxes.chemicals.active ? ` OR (a.Carries_CHEM = '')` : ``
        const construction = checkboxes.construction.checked ? ` OR (a.Carries_CONSTRUCT <> '')` : checkboxes.construction.active ? ` OR (a.Carries_CONSTRUCT = '')` : ``
        const freshproduce = checkboxes.freshproduce.checked ? ` OR (a.Carries_PRODUCE <> '')` : checkboxes.freshproduce.active ? ` OR (a.Carries_PRODUCE = '')` : ``
        const grainfeedhay = checkboxes.grainfeedhay.checked ? ` OR (a.Carries_GRAINFEED <> '')` : checkboxes.grainfeedhay.active ? ` OR (a.Carries_GRAINFEED = '')` : ``
        const liquidsgases = checkboxes.liquidsgases.checked ? ` OR (a.Carries_LIQGAS <> '')` : checkboxes.liquidsgases.active ? ` OR (a.Carries_LIQGAS = '')` : ``
        const machinerylargeobjects = checkboxes.machinerylargeobjects.checked ? ` OR (a.Carries_MACHLRG <> '')` : checkboxes.machinerylargeobjects.active ? ` OR (a.Carries_MACHLRG = '')` : ``
        const mobilehomes = checkboxes.mobilehomes.checked ? ` OR (a.Carries_MOBILEHOME <> '')` : checkboxes.mobilehomes.active ? ` OR (a.Carries_MOBILEHOME = '')` : ``
        const paperproducts = checkboxes.paperproducts.checked ? ` OR (a.Carries_PAPERPROD <> '')` : checkboxes.paperproducts.active ? ` OR (a.Carries_PAPERPROD = '')` : ``
        const usmail = checkboxes.usmail.checked ? ` OR (a.Carries_USMAIL <> '')` : checkboxes.usmail.active ? ` OR (a.Carries_USMAIL = '')` : ``
        const beverages = checkboxes.beverages.checked ? ` OR (a.Carries_BEVRAGES <> '')` : checkboxes.beverages.active ? ` OR (a.Carries_BEVRAGES = '')` : ``
        const coalcoke = checkboxes.coalcoke.checked ? ` OR (a.Carries_COALCOKE <> '')` : checkboxes.coalcoke.active ? ` OR (a.Carries_COALCOKE = '')` : ``
        const driveaway = checkboxes.driveaway.checked ? ` OR (a.Carries_DRIVETOW <> '')` : checkboxes.driveaway.active ? ` OR (a.Carries_DRIVETOW = '')` : ``
        const garbagerefuse = checkboxes.garbagerefuse.checked ? ` OR (a.Carries_GARBAGE <> '')` : checkboxes.garbagerefuse.active ? ` OR (a.Carries_GARBAGE = '')` : ``
        const householdgoods = checkboxes.householdgoods.checked ? ` OR (a.Carries_HOUSEHOLD <> '')` : checkboxes.householdgoods.active ? ` OR (a.Carries_HOUSEHOLD = '')` : ``
        const livestock = checkboxes.livestock.checked ? ` OR (a.Carries_LIVESTOCK <> '')` : checkboxes.livestock.active ? ` OR (a.Carries_LIVESTOCK = '')` : ``
        const meat = checkboxes.meat.checked ? ` OR (a.Carries_MEAT <> '')` : checkboxes.meat.active ? ` OR (a.Carries_MEAT = '')` : ``
        const motorvehicles = checkboxes.motorvehicles.checked ? ` OR (a.Carries_MOTOVEH <> '')` : checkboxes.motorvehicles.active ? ` OR (a.Carries_MOTOVEH = '')` : ``
        const passengers = checkboxes.passengers.checked ? ` OR (a.Carries_PASSENGERS  <> '')` : checkboxes.passengers.active ? ` OR (a.Carries_PASSENGERS  = '')` : ``
        const utilities = checkboxes.utilities.checked ? ` OR (a.Carries_UTILITY <> '')` : checkboxes.utilities.active ? ` OR (a.Carries_UTILITY = '')` : ``
        const buildingmaterials = checkboxes.buildingmaterials.checked ? ` OR (a.Carries_CARGOOTHER <> '')` : checkboxes.buildingmaterials.active ? ` OR (a.Carries_CARGOOTHER = '')` : ``
        const commoditiesdrybulk = checkboxes.commoditiesdrybulk.checked ? ` OR (a.Carries_DRYBULK <> '')` : checkboxes.commoditiesdrybulk.active ? ` OR (a.Carries_DRYBULK = '')` : ``
        const agriculturalfarmsupplies = checkboxes.agriculturalfarmsupplies.checked ? ` OR (a.Carries_FARMSUPP <> '')` : checkboxes.agriculturalfarmsupplies.active ? ` OR (a.Carries_FARMSUPP = '')` : ``
        const generalfreight = checkboxes.generalfreight.checked ? ` OR (a.Carries_GENFREIGHT <> '')` : checkboxes.generalfreight.active ? ` OR (a.Carries_GENFREIGHT = '')` : ``
        const intermodalcont = checkboxes.intermodalcont.checked ? ` OR (a.Carries_INTERMODAL <> '')` : checkboxes.intermodalcont.active ? ` OR (a.Carries_INTERMODAL = '')` : ``
        const logspolesbeamslumber = checkboxes.logspolesbeamslumber.checked ? ` OR (a.Carries_LOGPOLE <> '')` : checkboxes.logspolesbeamslumber.active ? ` OR (a.Carries_LOGPOLE = '')` : ``
        const metal = checkboxes.metal.checked ? ` OR (a.Carries_METALSHEET <> '')` : checkboxes.metal.active ? ` OR (a.Carries_METALSHEET = '')` : ``
        const oilfieldequipment = checkboxes.oilfieldequipment.checked ? ` OR (a.Carries_OILFIELD <> '')` : checkboxes.oilfieldequipment.active ? ` OR (a.Carries_OILFIELD = '')` : ``
        const refrigeratedfood = checkboxes.refrigeratedfood.checked ? ` OR (a.Carries_COLDFOOD <> '')` : checkboxes.refrigeratedfood.active ? ` OR (a.Carries_COLDFOOD = '')` : ``
        const waterwell = checkboxes.waterwell.checked ? ` OR (a.Carries_WATERWELL <> '')` : checkboxes.waterwell.active ? ` OR (a.Carries_WATERWELL = '')` : ``

        let query = chemicals + construction + freshproduce + grainfeedhay + liquidsgases + machinerylargeobjects 
                    + mobilehomes + paperproducts + usmail + beverages + coalcoke + driveaway + garbagerefuse
                    + householdgoods + livestock + meat + motorvehicles + passengers + utilities + buildingmaterials
                    + commoditiesdrybulk + agriculturalfarmsupplies + generalfreight + intermodalcont + logspolesbeamslumber
                    + metal + oilfieldequipment + refrigeratedfood + waterwell
        console.log(query)
        query = ' AND (' + query.replace(/^ OR\s*/, '') + ')'
        setUsdots({query: query})
        setCommoditiesopen(false)
    }
const [ customsearch, setCustomsearch]=useState(null)
    return (
        <>
            <Modal
                wrapClassName="modalofall"
                open={commoditiesopen}
                onCancel={() => setCommoditiesopen(false)}
                footer={null}
                width={1104}
                closeIcon={null}
                centered={false}
            >
                <div className="headerofmodal">
                    <span className="headertext">Insurance</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setCommoditiesopen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="WraperofModal wrapperof">
                    <div className="cardcomponent" style={{ width: "100%" }}>
                        <div className="row_wrapper_COVER">
                            {[
                                "General Freight",
                                "Household Goods",
                                "Metal: sheets, coils, rolls",
                                "Motor Vehicles",
                                "Drive/Tow Away",
                                "Logs, Poles, Beams, Lumber",
                                "Building Materials",
                                "Mobile Homes",
                                "Machinery, Large Objects",
                                "Fresh Produce",
                            ].map((label) => {
                                const key = labelKeys[label];
                                return (
                                    <div key={key} className="itemof_covers_Authority">
                                        <div className="tag_coverside">{label}</div>
                                        <div className="tag_cover">
                                            <Checkbox
                                                checked={checkboxes[key]?.checked}
                                                name={key}
                                                onChange={() => toggleCheckbox(key)}
                                            />
                                            <button
                                                className={`toggle-btn ${checkboxes[key]?.active ? 'active' : ''}`}
                                                onClick={() => toggleButton(key)}
                                            >
                                                {checkboxes[key]?.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="cardcomponent" style={{ width: "100%" }}>
                        <div className="row_wrapper_COVER">
                            {[
                                "Liquids/Gases",
                                "Intermodal Cont.",
                                "Passengers",
                                "Oilfield Equipment",
                                "Livestock",
                                "Grain, Feed, Hay",
                                "Coal/Coke",
                                "Meat",
                                "Garbage/Refuse",
                                "US Mail",
                                "Other - Custom search",
                            ].map((label) => {
                                const key = labelKeys[label];
                                return (
                                    <div key={key} className="itemof_covers_Authority">
                                        <div className="tag_coverside">{label}</div>
                                        <div className="tag_cover">
                                            <Checkbox
                                                checked={checkboxes[key]?.checked}
                                                name={key}
                                                onChange={() => toggleCheckbox(key)}
                                            />
                                            <button
                                                className={`toggle-btn ${checkboxes[key]?.active ? 'active' : ''}`}
                                                onClick={() => toggleButton(key)}
                                            >
                                                {checkboxes[key]?.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="newind">
                            <input onChange={(e)=>{setCustomsearch(e.target.value)}} type="text" className="cutomsearchinput" placeholder="Search" />
                        </div>
                    </div>
                    <div className="cardcomponent" style={{ width: "100%" }}>
                        <div className="row_wrapper_COVER">
                            {[
                                "Chemicals",
                                "Commodities Dry Bulk",
                                "Refrigerated Food",
                                "Beverages",
                                "Paper Products",
                                "Utilities",
                                "Agricultural/Farm Supplies",
                                "Construction",
                                "Water Well"
                            ].map((label) => {
                                const key = labelKeys[label];
                                return (
                                    <div key={key} className="itemof_covers_Authority">
                                        <div className="tag_coverside">{label}</div>
                                        <div className="tag_cover">
                                            <Checkbox
                                                checked={checkboxes[key]?.checked}
                                                name={key}
                                                onChange={() => toggleCheckbox(key)}
                                            />
                                            <button
                                                className={`toggle-btn ${checkboxes[key]?.active ? 'active' : ''}`}
                                                onClick={() => toggleButton(key)}
                                            >
                                                {checkboxes[key]?.active ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: "pointer" }} onClick={() => { setCommoditiesopen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={() => handleApply()}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Commodities;
