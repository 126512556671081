import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Modal } from 'antd';
import { Collapse } from 'antd';
import { initialItems, initialItems1, initialItems2, initialItems3, initialItems4 } from './MultipleStatesData';

const { Panel } = Collapse;

const Multiple = ({ setIsallopen, isallopen,showModal}) => {
  const [items, setItems] = useState(initialItems);
  const [checkboxGroups, setCheckboxGroups] = useState({
    items: initialItems,
    items1: initialItems1,
    items2: initialItems2,
    items3: initialItems3,
    // items4: initialItems4,
  });

  const groupNames = {
    items: "Carrier details",
    items1: "Fleet",
    items2: "License & Insurance",
    items3: "Cargo",
    // items4: "Cargo",
  };

  const handleCheckboxChange = (groupKey, checked) => {
    setCheckboxGroups(prevState => ({
      ...prevState,
      [groupKey]: prevState[groupKey].map(item => ({ ...item, checked })),
    }));
  };

  const handleSingleCheckboxChange = (groupKey, id, checked) => {
    setCheckboxGroups(prevState => ({
      ...prevState,
      [groupKey]: prevState[groupKey].map(item => 
        item.id === id ? { ...item, checked } : item
      ),
    }));
  };

  const handleAllCheckboxChange = (checked) => {
    const updatedGroups = {};
    for (let key in checkboxGroups) {
      updatedGroups[key] = checkboxGroups[key].map(item => ({ ...item, checked }));
    }
    setCheckboxGroups(updatedGroups);
  };

  const calculateSelected = (items) => items.filter(item => item.checked).length;

  const totalSelectedFields = Object.values(checkboxGroups).reduce((sum, group) => sum + calculateSelected(group), 0);
  const totalFields = Object.values(checkboxGroups).reduce((sum, group) => sum + group.length, 0);

  const columns = 3;

  return (
    <Modal
      wrapClassName="modalofall"
      open={isallopen}
      onCancel={() => setIsallopen(false)}
      footer={null}
      width={1104}
      closeIcon={null}
      centered={false}
    >
      <div className="headerofmodal justify-content-end">
        <div className='d-flex align-items-center justify-content-between ' style={{width:"55%"}}>
        <span className="headertext">Sales Search</span>
        <svg onClick={() => setIsallopen(false)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        </div>
      
      </div>
      <div className='componentofmodal p-3'>
        <div className="cardcomponent accordionfo">
          <div className="card-header" style={{ display: "flex", justifyContent: "center" }}>
            Reports Fields
          </div>
          <div className='allfields'>
            <Checkbox size='large' onChange={(e) => handleAllCheckboxChange(e.target.checked)} /> All fields ({totalSelectedFields}/{totalFields})
          </div>
          <Collapse className="mt-3" defaultActiveKey={['1']}>
            {Object.keys(checkboxGroups).map((key, index) => (
              <Panel
                expandIcon={null}
                key={index + 1}
                style={{ background: "#fff", borderTop: "none" }}
                header={
                  <div className="panel-header">
                    <div className='d-flex align-items-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path d="M16.75 7.5L10.5 13.75L4.25 7.5" stroke="#374147" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <Checkbox size='large' checked={checkboxGroups[key].every(item => item.checked)} onChange={(e) => handleCheckboxChange(key, e.target.checked)} />
                      <div className='headingtext'>{groupNames[key]} ({calculateSelected(checkboxGroups[key])}/{checkboxGroups[key].length})</div>
                    </div>
                  </div>
                }
              >
                <div className="Coverofmapfucntion" style={{ display: 'flex', flexDirection: 'row', padding: "20px" }}>
                  {[...Array(columns)].map((_, columnIndex) => (
                    <div key={columnIndex} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                      {checkboxGroups[key].slice(columnIndex * Math.ceil(checkboxGroups[key].length / columns), (columnIndex + 1) * Math.ceil(checkboxGroups[key].length / columns)).map(item => (
                        <div className='mapcover' key={item.id} style={{ marginBottom: '10px' }}>
                          <FormControlLabel
                            control={<Checkbox checked={item.checked} onChange={(e) => handleSingleCheckboxChange(key, item.id, e.target.checked)} />}
                            label={
                              <Typography style={{ fontWeight: item.checked ? 'bold' : 'normal' }}>
                                {item.name}
                              </Typography>
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
      {/* <div className='additionalsetting'>
              Additional Settings
            </div> */}
            {/* <div className='verifycheck' style={{width:"95%",margin:"auto"}}>
              <Checkbox checked={items.every(item => item.checked)} onChange={handleAllCheckboxChange} />     Save these fields as my default fields for SALEs reports
            </div> */}
            <div className='coverbtn'>
              <div className='lastcontinousbtn' onClick={() => showModal(checkboxGroups)}   >
                Continue
              </div>
            </div>
    </Modal>
  );
};

export default Multiple;
