const Quotes=()=>{
    return(
        <>
         <div className="cardcomponent">
                                <div className="card-header">
                                Quotes
                                </div>
                                <div className="coverover">

                               
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                <div className="row_wrapper drivedesc">
                                <div className="contentareafortable ">
                                                <span className="tabletitle2">Type quote:</span>
                                                <span className="tabledesc2">AL</span>
                                                <hr></hr>
                                            </div>
                                            <div className="contentareafortable">
                                                <span className="tabletitle2">Quantity:</span>
                                                <span className="tabledesc2">4</span>
                                                <hr></hr>
                                                 <div className="addreview">
                                                <div className="add">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#374147" stroke-miterlimit="10"/>
  <path d="M5.5 8H10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 5.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Add

                                                </div>
                                                <div className="review">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round"/>
</svg> View
                                                </div>
                                            </div>
                                            </div>
                                           
                                </div>
                                </div>
                            </div>
        </>
    )
}
export default Quotes;