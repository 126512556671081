import React from 'react'
import "./Client.css";
import { Modal } from "antd";
const NewClientModal = ({ showclient, setShowclient, dotnumber, setDotnumber, lead, setLead, state, setState, setStatus, renewaldate, setRenewaldate, handleSaveClient, status }) => {
    return (
        <>
            <Modal
                open={showclient}
                onCancel={() => setShowclient(false)}
                footer={null}
                closeIcon={null}
                width={600}
            >
                <div className="headerofmodal">
                    <span className="headertext">New client</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setShowclient(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">USDOT number:</span>
                            <input
                                type="text"
                                placeholder="231456977"
                                className="usdotinput"
                                value={dotnumber}
                                onChange={(e) => setDotnumber(e.target.value)}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Lead:</span>
                            <input
                                type="text"
                                placeholder="Modelme Freight Manager"
                                className="usdotinput"
                                value={lead}
                                onChange={(e) => setLead(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">State:</span>
                            <input
                                type="text"
                                placeholder="Ex. Sergio's customer base"
                                className="usdotinput"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Status:</span>
                            <input
                                type="text"
                                placeholder="Presented quote"
                                className="usdotinput"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Renewal date:</span>
                            <input
                                type="date"
                                placeholder="Ex. Sergio's customer base"
                                className="usdotinput"
                                value={renewaldate}
                                onChange={(e) => setRenewaldate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => { setShowclient(false) }}>Cancel</span>
                        <div className="submitbutton" onClick={handleSaveClient}>
                            Save client
                        </div>
                    </div>
                </div>
            </Modal>



        </>
    )
}

export default NewClientModal

