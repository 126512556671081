const ActivePendingInsurance = () => {
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Active/ Pending insurance
                </div>
                <div className="compinfo">
                    <div className="coverofcompinfo  ">
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Form:
                                </span>
                                <span className="descofinfo">
                                    91X
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Posted date:
                                </span>
                                <span className="descofinfo">
                                    02/13/2023
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Cancellation date:                                            </span>
                                <span className="descofinfo">
                                    -
                                </span>
                            </div>
                        </div>
                        <div className="infosection">

                            <div className="target">
                                <span className="titleofinfo">
                                    Type:
                                </span>
                                <span className="descofinfo">
                                    BIPD/ Primary
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Coverage from:
                                </span>
                                <span className="descofinfo">
                                    $0
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    <br></br>
                                </span>
                                <span className="descofinfo">
                                    <br></br><br></br>
                                </span>
                            </div>
                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Insurance carrier:
                                </span>
                                <span className="descofinfo">
                                    WESCO INSURANCE COMPANY
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Coverage to:
                                </span>
                                <span className="descofinfo">
                                    $750.000
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    <br></br>
                                </span>
                                <span className="descofinfo">
                                    <br></br><br></br>
                                </span>
                            </div>
                        </div>
                        <div className="infosection">
                            <div className="target">
                                <span className="titleofinfo">
                                    Policy/ Surety:
                                </span>
                                <span className="descofinfo">
                                    WMC 1989924
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    Effective date:
                                </span>
                                <span className="descofinfo">
                                    02/14/2023
                                </span>
                            </div>
                            <div className="target">
                                <span className="titleofinfo">
                                    <br></br>
                                </span>
                                <span className="descofinfo">
                                    <br></br><br></br>
                                </span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default ActivePendingInsurance;