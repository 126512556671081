import { Checkbox, Modal } from 'antd';
import trueimage from './true.png';
import falseimage from './crosss.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../Constants/BaseUrl';

const PaymentFormComponent = ({ subscriptionId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errormodal, setErrormodal] = useState(false);

  const [formData, setFormData] = useState({
    cardholderName: '',
    creditCardNumber: '',
    expDate: '',
    cvv: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    zipCode: '',
    customerId:''
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Load existing subscription data on mount
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/users/${subscriptionId}`);
        const { customerId,cardNumber, expirationDate, cardCode, billingAddress } = response.data.data;

        setFormData({
          cardholderName: `${billingAddress.firstName} ${billingAddress.lastName}` || '',
          creditCardNumber: cardNumber || '',
          expDate: expirationDate || '',
          cvv: cardCode || '',
          billingAddress: billingAddress.address || '',
          billingCity: billingAddress.city || '',
          billingState: billingAddress.state || '',
          zipCode: billingAddress.zip || '',
          customerId: customerId || ''
        });
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      }
    };

    fetchSubscriptionData();
  }, [subscriptionId]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'expDate') {
      let formattedValue = value.replace(/[^0-9\-]/g, '');
      if (formattedValue.length === 4 && !formattedValue.includes('-')) {
        formattedValue += '-';
      }
      if (formattedValue.length > 7) {
        formattedValue = formattedValue.substring(0, 7);
      }
      setFormData({ ...formData, [id]: formattedValue });
    } else {
      setFormData({ ...formData, [id]: value });
    }
    setErrors({ ...errors, [id]: '' });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let hasError = false;

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'This field is required';
        hasError = true;
      }
    });

    setErrors(newErrors);

    if (hasError) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${SERVER_URL}/payment/update`, {
        subscriptionId:formData.customerId,  // Include subscriptionId in update request
        cardholderName: formData.cardholderName,
        cardNumber: formData.creditCardNumber,
        expirationDate: formData.expDate,
        cardCode: formData.cvv,
        city: formData.billingCity,
        state: formData.billingState,
        zipCode: formData.zipCode,
        intervalLength: 30,  // Example values; update these as necessary
        totalOccurrences: 9999
      });

      setIsModalVisible(true);
      setIsLoading(false);
      console.log('Subscription updated successfully:', response.data);
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating subscription:', error);
      setErrormodal(true)
    }
  };

  const errorStyle = {
    borderColor: 'red',
    borderWidth: '2px'
  };

  return (
    <>
      <form className="row g-3 p-5" style={{ width: "100%" }} onSubmit={handleFormSubmit}>
        <div className="col-md-12">
          <label htmlFor="cardholderName" className="form-label">Cardholder Name</label>
          <input
            type="text"
            className="form-control"
            id="cardholderName"
            placeholder="Cardholder Name"
            value={formData.cardholderName}
            onChange={handleChange}
            style={errors.cardholderName ? errorStyle : {}}
          />
        </div>
        <div className="col-md-8">
          <label htmlFor="creditCardNumber" className="form-label">Credit Card Number</label>
          <input
            type="text"
            className="form-control"
            id="creditCardNumber"
            placeholder="Credit Card Number"
            value={formData.creditCardNumber}
            onChange={handleChange}
            style={errors.creditCardNumber ? errorStyle : {}}
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="expDate" className="form-label">Exp Date</label>
          <input
            type="text"
            className="form-control"
            id="expDate"
            placeholder="YYYY-MM"
            value={formData.expDate}
            onChange={handleChange}
            style={errors.expDate ? errorStyle : {}}
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="cvv" className="form-label">CVV</label>
          <input
            type="text"
            className="form-control"
            id="cvv"
            placeholder="CVV"
            value={formData.cvv}
            onChange={handleChange}
            style={errors.cvv ? errorStyle : {}}
          />
        </div>
        <div className="col-12">
          <label htmlFor="billingAddress" className="form-label">Billing Address</label>
          <input
            type="text"
            className="form-control"
            id="billingAddress"
            placeholder="Billing Address"
            value={formData.billingAddress}
            onChange={handleChange}
            style={errors.billingAddress ? errorStyle : {}}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="billingCity" className="form-label">City</label>
          <input
            type="text"
            className="form-control"
            id="billingCity"
            placeholder="City"
            value={formData.billingCity}
            onChange={handleChange}
            style={errors.billingCity ? errorStyle : {}}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="billingState" className="form-label">State</label>
          <input
            type="text"
            className="form-control"
            id="billingState"
            placeholder="State"
            value={formData.billingState}
            onChange={handleChange}
            style={errors.billingState ? errorStyle : {}}
          />
        </div>
        <div className="col-md-2">
          <label htmlFor="zipCode" className="form-label">Zip Code</label>
          <input
            type="text"
            className="form-control"
            id="zipCode"
            placeholder="Zip Code"
            value={formData.zipCode}
            onChange={handleChange}
            style={errors.zipCode ? errorStyle : {}}
          />
        </div>
        <div className="col-12 mt-3 d-flex gap-2 selecttext">
          <Checkbox />
          <div>
            By selecting Subscribe, I authorize Freight Insurance Data to charge my Credit Card and update my subscription accordingly. I agree to the <span className="linkertext">Terms of Service</span> and acknowledge the <span className="linkertext">Privacy Statement.</span>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button type="submit" style={{ width: '100%' }} className="buttonofpayment" disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Update Payment Information'}
          </button>
        </div>
      </form>
      <Modal
        open={isModalVisible}
        width={379}
        closeIcon={null}
        footer={null}
      >
        <div className='centermodal p-2 d-flex jutify-content-center align-items-center text-center'>
          <div className='forimg'>
            <img src={trueimage} alt='done' />
          </div>
          <div className='strongdiv'>
            Subscription Updated Successfully
          </div>
          <div className='textexpl'>
            Your payment information has been updated successfully.
          </div>
          <button
          className="successbtn2"
          onClick={() => {
            setIsModalVisible(false);
            window.location.href = "/dashboard"; // Redirect to dashboard or relevant page
          }}
        >
          Go to Dashboard
        </button>
        </div>
       
      </Modal>
      <Modal
        open={errormodal}
        width={379}
        closeIcon={null}
        footer={null}
      >
        <div className='centermodal p-2 d-flex jutify-content-center align-items-center text-center'>
          <div className='forimg'>
            <img src={falseimage} alt='done' />
          </div>
          <div className='strongdiv'>
            Something Went Wrong
          </div>
          <div className='textexpl'>
            Please Enter Correct Information You Must have Enough Credit
          </div>
          <button
          className="successbtn2"
          onClick={() => {
            setErrormodal(false)}}>
          Try Again
        </button>
        </div>
       
      </Modal>
    </>
  );
};

export default PaymentFormComponent 
