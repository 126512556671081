import { useState } from "react";
import "../Usdot.css"
import "../Drive.css"
import { Modal, Skeleton } from "antd";
import axios from "axios";
import DeepDriveDetail from "./DeepDriveDetails";
import VehicleModal from "./VehicleModal";
import VerifyVinNumber from "./VerifyVinNumberModal";
const DeepDrive = () => {
    const [loading, setLoading] = useState(false)
    const [vinresponse, setVinresponse] = useState("")
    const [isNewModalVisible, setNewModalVisible] = useState(false);
    const [showclient, setShowclient] = useState(false);
    const [Vin, setVin] = useState("")
    const [year, setYear] = useState("")
    const [make, setMake] = useState("")
    const [model, setModel] = useState("")
    const [truckType, setTruckType] = useState('');
    const [statedValue, setStatedValue] = useState('');



    const [Vehicles, setVehicles] = useState([
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
        { Year: "2007", Make: "Freightliner", Model: "Columbia", VIN: "1FUJA6CK17PY50909", Trucktype: "Tractor", Statedvalue: "22000" },
    ]);

    const handleSaveVehicle = () => {
        const newVehicle = {
            Year: year,
            Make: make,
            Model: model,
            VIN: Vin,
            Trucktype: truckType,
            Statedvalue: statedValue
        };

        // Create a new array by concatenating the existing vehicles array with the new vehicle
        const updatedVehicles = [...Vehicles, newVehicle];

        // Update the state with the new array of vehicles
        setVehicles(updatedVehicles);

        // Reset input fields and close the modal
        setMake("");
        setModel("");
        setTruckType("");
        setVin("");
        setYear("");
        setStatedValue("");
        setShowclient(false);
    };

    const closeNewModal = () => {
        setVin("")
        setNewModalVisible(false);
    };
    const doneNewModal = () => {
        setNewModalVisible(false);

    };
    const openNewModal = () => {
        setNewModalVisible(true);
    };
    const lookupvinnumber = (e) => {
        // Set loading to true when the function starts
        e.preventDefault()
        setLoading(true);
        openNewModal()
        // Perform the API request
        axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinExtended/${Vin}?format=json`)
            .then(res => {
                // Handle the response data
                setMake(res.data.Results[7].Value);
                setYear(res.data.Results[10].Value);
                setModel(res.data.Results[9].Value);
                setTruckType(res.data.Results[51].value)
                setVinresponse(res.data.Results)
            })
            .catch(error => {
                // Handle errors if needed
                console.error('Error fetching VIN information:', error);
            })
            .finally(() => {
                // Set loading to false when the request completes (either success or failure)
                setLoading(false);
            });
    };

    return (
        <>
            <DeepDriveDetail Vehicles={Vehicles} setVehicles={setVehicles} setShowclient={setShowclient} />

            <VehicleModal 
             setShowclient={setShowclient} 
             setYear={setYear} setMake={setMake} 
             setModel={setModel} 
             setVin={setVin} 
             setTruckType={setTruckType} 
             setStatedValue={setStatedValue} 
             handleSaveVehicle={handleSaveVehicle} 
             showclient={showclient}
             Modal={Modal}
             Vin={Vin}
             lookupvinnumber={lookupvinnumber}
             truckType={truckType}
             statedValue={statedValue}
              />
         <VerifyVinNumber  
           doneNewModal={doneNewModal}
           closeNewModal={closeNewModal}
           vinresponse={vinresponse}
           Modal={Modal}
           isNewModalVisible={isNewModalVisible}
           loading={loading}
           Skeleton={Skeleton}
           Vin={Vin}
         />

        </>


    )
}
export default DeepDrive;
