import { Modal, Select, Tag } from "antd"
import { useState } from "react"
import { DatePicker } from "antd";
import Checkbox from '@mui/material/Checkbox';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;
const Equipment = ({ equipmentOpen, setEquipmentOpen }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selections, setSelections] = useState({ include: [], exclude: [] });
    const [isActive, setIsActive] = useState({
        busActive: false,
        incompleteVehcileActive: false,
        jeepActive: false,
        isukuActive: false,
        phoenixActive: false,
        icBusActive: false,
    });
    const [checked, setChecked] = useState({
        bus: false,
        incompleteVehcile: false,
        jeep: false,
        isuku: false,
        phoenix: false,
        icBus: false,
        frameTrailer: false,
        atv: false,
        aerial: false,
        angelIcon: false,
        angelIconCar: false
    });

    const handleChange = (event, classType) => {
        const { checked } = event.target;
        setChecked(prevState => ({
            ...prevState,
            [classType]: checked
        }));

        setIsActive(prevState => ({
            ...prevState,
            [`${classType}Active`]: false
        }));

        setSelections(prevSelections => {
            const type = checked ? 'include' : 'exclude';
            const otherType = checked ? 'exclude' : 'include';
            const newOtherType = prevSelections[otherType].filter(item => item !== classType);

            return {
                ...prevSelections,
                [type]: [...prevSelections[type], classType],
                [otherType]: newOtherType
            };
        });
    };

    const toggleActive = (classType) => {
        setIsActive(prevState => {
            const newState = {
                ...prevState,
                [classType]: !prevState[classType]
            };
            const vehicle = classType.replace('Active', '');
            setChecked(prevChecked => ({
                ...prevChecked,
                [vehicle]: false
            }));
            setSelections(prevSelections => {
                const newInclude = prevSelections.include.filter(item => item !== vehicle);
                return {
                    include: newInclude,
                    exclude: [...prevSelections.exclude, vehicle]
                };
            });
            return newState;
        });
    };
    const initialCheckboxes = {
        class1: { checked: false, active: false },
        class2: { checked: false, active: false },
        class3: { checked: false, active: false },
        class4: { checked: false, active: false },
        class5: { checked: false, active: false },
        class6: { checked: false, active: false },
        class7: { checked: false, active: false },
        class8: { checked: false, active: false },
    };

    const [checkboxes, setCheckboxes] = useState(initialCheckboxes);

    const toggleCheckbox = (event, field) => {
        setCheckboxes((prevState) => ({
            ...prevState,
            [field]: {
                checked: !prevState[field]?.checked,
                active: false, // Ensure active is false when checked is true
            },
        }));
    };
 
    const toggleButton = (field) => {
        setCheckboxes((prevState) => ({
            ...prevState,
            [field]: {
                active: !prevState[field]?.active,
                checked: false, // Ensure checked is false when active is true
            },
        }));
    };
    return (
        <>
            <Modal
                open={equipmentOpen}
                onCancel={() => setEquipmentOpen(false)}
                footer={null}
                width={1306}
                closeIcon={null}
                centered={false}
            >

                <div className="headerofmodal">
                    <span className="headertext">Insurance</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setEquipmentOpen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>

                <div className="coverofmodalforlength EquipmentAuthority" >
                    <div className="cardcomponent" style={{ width: "464px" }}>
                        <div className="card-header">
                            GVWR Class
                        </div>
                        <div className="row_wrapper_table">
                            <div className="table_content">
                                <div className="coverofgreen_grey">
                                    <div className="green_side">
                                        Federal Highway administration
                                    </div>
                                    <div className="grey_side">
                                        Vehicles
                                    </div>
                                </div>
                                <div className="header_table_black">
                                    Gross vehicle
                                    weight rating
                                    (ibs)
                                </div>
                                <div className="header_table_empty">

                                </div>

                            </div>
                            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 1: <span>&lt;6,000lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class1.checked}
                        onChange={(event) => toggleCheckbox(event, "class1")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class1.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class1")}
                    >
                        {checkboxes.class1.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 2: <span>6,001 - 10,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class2.checked}
                        onChange={(event) => toggleCheckbox(event, "class2")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class2.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class2")}
                    >
                        {checkboxes.class2.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 3: <span>10,001 - 14,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class3.checked}
                        onChange={(event) => toggleCheckbox(event, "class3")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class3.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class3")}
                    >
                        {checkboxes.class3.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 4: <span>14,001 - 16,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class4.checked}
                        onChange={(event) => toggleCheckbox(event, "class4")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class4.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class4")}
                    >
                        {checkboxes.class4.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 5: <span>16,001 - 19,500 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class5.checked}
                        onChange={(event) => toggleCheckbox(event, "class5")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class5.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class5")}
                    >
                        {checkboxes.class5.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 6: <span>19,501 - 26,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class6.checked}
                        onChange={(event) => toggleCheckbox(event, "class6")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class6.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class6")}
                    >
                        {checkboxes.class6.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 7: <span>26,001 - 33,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class7.checked}
                        onChange={(event) => toggleCheckbox(event, "class7")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class7.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class7")}
                    >
                        {checkboxes.class7.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>
            <div className="Normal_Row_Table">
                <div className="Left_row_table">
                    Class 8: <span>'' 33,000 lbs</span>
                </div>
                <div className="black_row_table">
                    <span>&lt;6,000</span>
                </div>
                <div className="tag_cover cehck">
                    <Checkbox
                        checked={checkboxes.class8.checked}
                        onChange={(event) => toggleCheckbox(event, "class8")}
                    />
                    <button
                        className={`toggle-btn ${checkboxes.class8.active ? 'active' : ''}`}
                        onClick={() => toggleButton("class8")}
                    >
                        {checkboxes.class8.active ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        ) : null}
                    </button>
                </div>
            </div>

                        </div>

                    </div>
                    <div>
                        <div className="cardcomponent" style={{ width: "464px", height: "170px" }}>
                            <div className="card-header">
                                Operation authority
                            </div>
                            <div className="induvidual_content newind">
                                Exclude motor carriers operating 
                                <Select
   className="custom-selectitem" defaultValue=" power units/trailers"
  >
    <Option value="option1">Option 1</Option>
    <Option value="option2">Option 2</Option>
  </Select>   older than <input className="daysinput" type="number"/>   years.
                            </div>
                            <div className="under_indu">
                                Based on last 24 months of inspections
                            </div>

                        </div>
                        <div className="cardcomponent" style={{ width: "464px" }}>
                            <div className="card-header">
                                Makes
                            </div>
                            <div className="selections pt-3 px-3">
                {selections.include.map(vehicle => (
                    <Tag color="blue" key={vehicle}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M10.9727 4.21875L5.13932 10.0521L2.22266 7.13542" stroke="#374147" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg> {vehicle}</Tag>
                ))}
                {selections.exclude.map(vehicle => (
                    <Tag color="red" key={vehicle}> <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path d="M9 1L1 9" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 9L1 1" stroke="#374147" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg> {vehicle}</Tag>
                ))}
            </div>
                            <Collapse className="mt-3" defaultActiveKey={['1']}>
                                <Panel
                                    style={{ background: "#fff", borderTop: "none" }}
                                    header={
                                        <div className="panel-header">
                                            <span>BUS</span>
                                            <div className="custom-box">84</div>

                                        </div>
                                    }
                                    key="1">
                                    <div className="tag_cover truck_side">
                                        <div className="d-flex align-items-center">
                                            <Checkbox 
                                            checked={checked?.bus}
                                           onChange={(event) => handleChange(event, "bus")}
                                        
                                        
                                        />

                                            <button
                                                className={`toggle-btn ${isActive.icBusActive ? 'active' : ''}`}
                                                onClick={() => toggleActive("icBusActive")}
                                            >
                                                {isActive?.icBusActive ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                ) : null}
                                            </button>
                                        </div>
                                        IC Bus
                                    </div>
                                </Panel>
                                <Panel style={{ background: "#fff", borderTop: "none" }}
                                    header={
                                        <div className="panel-header">
                                            <span>Incomplete vehcile</span>
                                            <div className="custom-box">84</div>

                                        </div>
                                    } key="2">
                                    <div className="tag_cover truck_side">
                                        <div className="d-flex align-items-center">
                                        <Checkbox
                                        checked={checked.incompleteVehcile}
                                        onChange={(event) => handleChange(event, "incompleteVehcile")}
                                    />
                                    <button
                                        className={`toggle-btn ${isActive.incompleteVehcileActive ? 'active' : ''}`}
                                        onClick={() => toggleActive("incompleteVehcileActive")}
                                    >
                                        {isActive.incompleteVehcileActive ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        ) : null}
                                    </button>
                                        </div>
                                        IC Bus
                                    </div>
                                </Panel>
                               

            <Panel style={{ background: "#fff", borderTop: "none" }}
                header={
                    <div className="panel-header">
                        <span>Truck</span>
                        <div className="custom-box">84</div>
                    </div>
                } key="3">
                <div className="tag_cover truck_side">
                    <div className="d-flex align-items-center">
                        <Checkbox
                            checked={checked.phoenix}
                            onChange={(event) => handleChange(event, "phoenix")}
                        />
                        <button
                            className={`toggle-btn ${isActive.phoenixActive ? 'active' : ''}`}
                            onClick={() => toggleActive("phoenixActive")}
                        >
                            {isActive.phoenixActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : null}
                        </button>
                    </div>
                    Indiana phoenix inc
                </div>
                <div className="tag_cover truck_side">
                    <div className="d-flex align-items-center">
                        <Checkbox
                            checked={checked.jeep}
                            onChange={(event) => handleChange(event, "jeep")}
                        />
                        <button
                            className={`toggle-btn ${isActive.jeepActive ? 'active' : ''}`}
                            onClick={() => toggleActive("jeepActive")}
                        >
                            {isActive.jeepActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : null}
                        </button>
                    </div>
                    Jeep
                </div>
                <div className="tag_cover truck_side">
                    <div className="d-flex align-items-center">
                        <Checkbox
                            checked={checked.isuku}
                            onChange={(event) => handleChange(event, "isuku")}
                        />
                        <button
                            className={`toggle-btn ${isActive.isukuActive ? 'active' : ''}`}
                            onClick={() => toggleActive("isukuActive")}
                        >
                            {isActive.isukuActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : null}
                        </button>
                    </div>
                    ISUKU
                </div>
                <div className="tag_cover truck_side">
                    <div className="d-flex align-items-center">
                        <Checkbox
                            checked={checked.icBus}
                            onChange={(event) => handleChange(event, "icBus")}
                        />
                        <button
                            className={`toggle-btn ${isActive.icBusActive ? 'active' : ''}`}
                            onClick={() => toggleActive("icBusActive")}
                        >
                            {isActive.icBusActive ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M12.7358 7.99805H3.59961" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            ) : null}
                        </button>
                    </div>
                    IC Bus
                </div>
            </Panel>
                            </Collapse>

                        </div>
                    </div>
                    <div className="cardcomponent" style={{ width: "100%", height: "504px" }}>
                        <div className="card-header">
                            Trailer types
                        </div>

                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.frameTrailer}
                                        onChange={(event) => handleChange(event, "frameTrailer")} style={{ padding: "0px" }} />
                                A frame trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.atv} onChange={(event) => handleChange(event, "atv")} style={{ padding: "0px" }} />
                                Atv trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.aerial} onChange={(event) => handleChange(event, "aerial")} style={{ padding: "0px" }} />
                                Aerial
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIcon} onChange={(event) => handleChange(event, "angelIcon")} style={{ padding: "0px" }} />
                                Angel Icon
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIconCar} onChange={(event) => handleChange(event, "angelIconCar")} style={{ padding: "0px" }} />
                                Angel Icon,car Trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIconCar} onChange={(event) => handleChange(event, "angelIconCar")} style={{ padding: "0px" }} />
                                Angel Icon,car Trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIconCar} onChange={(event) => handleChange(event, "angelIconCar")} style={{ padding: "0px" }} />
                                Angel Icon,car Trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIconCar} onChange={(event) => handleChange(event, "angelIconCar")} style={{ padding: "0px" }} />
                                Angel Icon,car Trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>
                        <div className="all_frame_trailer">
                            <div className="checkbos_sider d-flex gap-2 align-items-center">
                                <Checkbox checked={checked.angelIconCar} onChange={(event) => handleChange(event, "angelIconCar")} style={{ padding: "0px" }} />
                                Angel Icon,car Trailer
                            </div>
                            <div className="custom-box">84</div>


                        </div>

                    </div>
                </div>
                <div className="bottom_button_for_modal">
                    <span className="clear_Button"  style={{ cursor: "pointer" }} onClick={() => { setEquipmentOpen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={() => setEquipmentOpen(false)}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Equipment