
const Scroller=()=> {


  return (
    <div className="container">
      <div className="row ">
        <div className="col-md-6 coverofscroller ">
          <h1>one side of the scroller effect</h1>
          <h1>For testing Purpose</h1>
        </div>
        <div className="col-md-6">

          <div className="card">Card 1</div>
          <div className="card">Card 2</div>
          <div className="card">Card 3</div>
          <div className="card">Card 4</div>

        </div>
      </div>
      <div className="anothertavke">
        hello
      </div>
      <div className="anothertavke">
        hello
      </div>
    </div>
  );
}

export default Scroller;
