import { create } from 'zustand';
import axios from 'axios';

const initialCheckboxes =  {
    authorizedforhire: { checked: false, active: false },
    exemptforhire: { checked: false, active: false },
    federalgovernment: { checked: false, active: false },
    indiantribe: { checked: false, active: false },
    localgovernment: { checked: false, active: false },
    migrant: { checked: false, active: false },
    other: { checked: false, active: false },
    privpassbusiness: { checked: false, active: false },
    privpassnonbusiness: { checked: false, active: false },
    privateproperty: { checked: false, active: false },
    stategovernment: { checked: false, active: false },
    usmail: { checked: false, active: false },
    interstate: { checked: false, active: false },
    carriertypeoptions: { checked: false, active: false },
    intrastatehazmat: { checked: false, active: false },
    intrastatenonhazmat:{checked:false,active:false}
};


const operationstore = create((set) => ({
  operations: [],
  checkboxes1: initialCheckboxes, 
  isLoading: false,
  error: null,

  // Fetch commodities
  fetchoperations: async () => {
    try {
      set({ isLoading: true, error: null });
      const response = await axios.get('https://jsonplaceholder.typicode.com/operations');
      const operations = response.data;
      set({ operations, isLoading: false });
    } catch (error) {
      console.error('Error fetching operations:', error);
      set({ isLoading: false, error: 'Failed to fetch operations' });
    }
  },

  // Toggle checkbox state
  toggleCheckbox: (field) => {
    set((state) => ({
      checkboxes1: {
        ...state?.checkboxes1,
        [field]: {
          checked: !state?.checkboxes1[field]?.checked,
          active: false,
        },
      },
    }));
  },

  // Toggle button state
  toggleButton: (field) => {
    set((state) => ({
      checkboxes1: {
        ...state?.checkboxes1,
        [field]: {
          checked: false,
          active: !state?.checkboxes1[field]?.active,
        },
      },
    }));
  },

  removeFieldState1: (field) => {
    set((state) => ({
      checkboxes1: {
        ...state?.checkboxes1,
        [field]: {
          checked: false,
          active: false,
        },
      },
    }));
  },
}));

export default operationstore;
