import React, { useEffect, useState } from 'react'
import AccountNav from '../Navbar/AccountNav'
import { DatePicker, message } from 'antd';
import TableCollaborators from './TableCollaborators';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../Constants/BaseUrl';

const customStyle = {
  display: 'flex',
  height: '48px',
  padding: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid #E0E0E0',
  background: '#FFF'
};

function Collaborators() {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
};
const [collaborators, setCollaborators] = useState([]);
const [user, setUser] = useState(null);
const [loading, setLoading] = useState(true);
const [userinfo,setUserInfo]=useState(false)

useEffect(() => {
  // Fetch user ID from localStorage
  const userId = localStorage.getItem('userId');

  // Make GET request to fetch user data based on ID
  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/users/${userId}`);
      setUserInfo(response.data.data);
      setLoading(false); // Set loading to false after successful data fetch
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false); // Set loading to false on error
      // Handle error
    }
  };

  if (userId) {
    setUser(userId);
    fetchUserData();
  }
}, []); 
const fetchCollaborators = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/collaborators/${user}`);
    setCollaborators(response.data);
  } catch (error) {
    console.error('Error fetching collaborators:', error);
  }
};
useEffect(() => {


  if (user) {
    fetchCollaborators();
  }
}, [user]);
const navigate = useNavigate()
const gotocollaboratorspage = () => {
  if (userinfo.purchasedcategory === 'Starter') {
    message.error('You are not allowed to create a Collaborators with the Starter Plan. Please upgrade your plan.');
  } else if (userinfo.purchasedcategory === 'Business') {
    if (collaborators.length >= 3) {
      message.error('You have reached the maximum number of collaborators for the Business Plan. Please upgrade your plan.');
    } else {
      navigate('/home/add-collaborators');
    }
  } else if (userinfo.purchasedcategory === 'Enterprise') {
    navigate('/home/add-collaborators');
  }
}

  return (
   <>
    <div className='user_main'>
      <div  >
        <AccountNav />
      </div>
      <div className='main_content'>
        <div style={{width:'90%'}}>
        <div className='collaborator_Main'>
      <div className='mian_text_collaborator'>
      <div >
      <div className='usertitleinfo_col'>List of Collaborators</div>
<div className='numberof_col'>
Total of {collaborators.length } Collaborators
</div>
        </div>
        {/* <Link to='/home/add-collaborators'> */}
<div className='add_col_btn' onClick={gotocollaboratorspage}>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M3.125 10H16.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 3.125V16.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 Add Collaborator
</div>

      </div>
<div>
  <div className='head_col'>
  Entry Date
  </div>
  <DatePicker style={customStyle} />
  </div>
     </div>
     <TableCollaborators/>
        </div>
  

      </div>
      </div>
   </>
  )
}

export default Collaborators