import React, { useState } from 'react';
import { Modal, Select, message } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../Constants/BaseUrl';

const { Option } = Select;

const stateMapping = {
  "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA",
  "Colorado": "CO", "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA",
  "Hawaii": "HI", "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Iowa": "IA",
  "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA", "Maine": "ME", "Maryland": "MD",
  "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN", "Mississippi": "MS", "Missouri": "MO",
  "Montana": "MT", "Nebraska": "NE", "Nevada": "NV", "New Hampshire": "NH", "New Jersey": "NJ",
  "New Mexico": "NM", "New York": "NY", "North Carolina": "NC", "North Dakota": "ND", "Ohio": "OH",
  "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA", "Rhode Island": "RI", "South Carolina": "SC",
  "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX", "Utah": "UT", "Vermont": "VT",
  "Virginia": "VA", "Washington": "WA", "West Virginia": "WV", "Wisconsin": "WI", "Wyoming": "WY"
};

const UsaStatesModal = ({ visible, setVisible, statesAllowed, subscriptionid }) => {
  const [selectedStates, setSelectedStates] = useState([]);

  // Convert abbreviations to full names for display purposes
  const stateOptions = Object.keys(stateMapping).map(fullName => ({
    fullName,
    abbreviation: stateMapping[fullName]
  }));

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleChange = (value) => {
    if (value.length <= statesAllowed) {
      setSelectedStates(value);
    }
  };

  const handleSubmit = async () => {
    try {
      // Convert full names to abbreviations
      const selectedAbbreviations = selectedStates.map(fullName => stateMapping[fullName]);

      await axios.put(`${SERVER_URL}/userdata/${subscriptionid}`, {
        states: selectedAbbreviations,
      });

      message.success('States updated successfully');
      handleCloseModal();
    } catch (error) {
      message.error('Error updating states');
      console.error('Error updating states:', error);
    }
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closeIcon={null}
    >
      <>
        <div className="headerofmodal" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <span className="headertext" style={{ fontSize: '16px', fontWeight: 'bold' }}>Select States</span>
          <svg style={{ cursor: "pointer" }} onClick={handleCloseModal} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>

        <div className='p-4'>
          <div className='cancelbtn mb-3'>This action is irreversible: once you select the states for Leads, it cannot be changed.</div>

          <Select
            mode="multiple"
            placeholder="Please select states"
            value={selectedStates}
            onChange={handleChange}
            style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: '4px' }}
          >
            {stateOptions.map(({ fullName, abbreviation }) => (
              <Option key={abbreviation} value={fullName}>
                {fullName}
              </Option>
            ))}
          </Select>

          <div className='footerButton' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <div className='cancelbtn' onClick={handleCloseModal} style={{ cursor: 'pointer' }}>Cancel</div>
            <div className='submitbutton' onClick={handleSubmit} style={{ cursor: 'pointer' }}>Submit</div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UsaStatesModal;
