import businessLengthstore from "../../Redux/Stores/Leads/businessLength"
import { Modal } from "antd"
import { useState } from "react"
import { Checkbox, DatePicker } from "antd";
import axios from 'axios';
import { BASE_URL } from "../../Constants/BaseUrl";
const BusinessLength = ({ isBusinessOpen, setIsBusinessOpen, setUsdots }) => {
    const { selectedDate, selectedDate1, selectedDate2, selectedDate3, input, input1, selectedCheck, from, to, handleCheck, handleDateChange, handleDateChange1, handleDateChange2, handleDateChange3, handleInputChange, handleInputChange1 } = businessLengthstore()
    const getUsdots = async (q) => {
        try {
            const response = await axios.post(`${BASE_URL}/getData`, { query: q });
            if (response.data.hasOwnProperty('result')) {
                const list = response.data['result']
                return list
            }
        } catch (error) {
            return []
        }
    }


    const handleApply = () => {
        let set = new Set()
        let query = ""
        if(selectedCheck == 1){
            query = " and cast(cast(a.record_add_date as varchar) as date) >= DATEADD(DAY, -"+ input +", GETDATE())"
        }else if (selectedCheck == 2){
            query = ` join (select distinct * from (
                    select
                    rank()over(PARTITION by usdot_number order by cast(cast(final_authority_served_date as varchar) as date) desc
                    , cast(cast(final_authority_decision_date as varchar) as date) desc
                    , cast(cast(final_authority_served_date as varchar) as date) desc) as ranks -- RANKS BY final_authority_served_date DESC TO ENSURE LATEST INFORMATION IS UP TO DATE
                    , *
                    from authhist_allwithhistory aa
                    where usdot_number >= 1 -- THIS DISALLOWS USDOT#'S THAT ARE ONLY 0
                    ) a
                    where 
                    -- #1,2,3,4 FILTER TO ENSURE ONLY ACTIVE COMPANIES APPEAR IN QUERY. 	#5,6 FILTERS AUTHORITY ACTIVATED IN CERTAIN AMOUNT OF PREVIOUS DAYS
                    (ranks = 1 -- 1. THIS FILTERS ONLY MOST RECENT AUTHORITY HISTORY
                    and 
                    ORIGNAL_AUTHORITY_ACTION_DESCRIPTION IN ('REINSTATED','GRANTED') -- 2. FILTERS CO THAT MOST RECENT AUTH DESC IS REINSTATED OR GRANTED
                    and  
                    FINAL_AUTHORITY_ACTION_DESCRIPTION = '' -- 3. ALSO FITLERS WHERE NO FINAL AUTH DESC IS AVAILABLE. MEANING ORGINIAL AUTH DESC IS REINSTATED OR GRANTED WITH NO REVOCATION
                    and
                    LEN(ORIGNAL_AUTHORITY_ACTION_SERVED_DATE) > 0 -- 4. FILTERS WHERE THERES A ORIGINAL AUTH SERVED DATE PRESENT (SHOULDN'T BE EMPTY)
                    and 
                    (
                    CAST(CAST(ORIGNAL_AUTHORITY_ACTION_SERVED_DATE AS VARCHAR) AS DATE) >= DATEADD(DAY, -`+input1+`, GETDATE()) -- 5. THIS FILTERS TO QUERY COMPANIES WHO HAVE HAD THEIR AUTH GRANTED IN PREVIOUS X AMOUNT OF DAYS
                    OR 
                    CAST(CAST(final_authority_served_date AS VARCHAR) AS DATE) >= DATEADD(DAY, -`+input1+`, GETDATE()) -- 6. THIS FILTERS TO QUERY CO. WHO HAVE WHO HAVE HAD THEIR AUTH REINSTATED IN PREVIOUS X AMOUNT OF DAYS
                    )
                    ) 
                    OR 
                    (ranks = 1 -- THIS FILTERS ONLY MOST RECENT AUTHORITY HISTORY
                    and 
                    ORIGNAL_AUTHORITY_ACTION_DESCRIPTION in ('INVOLUNTARY REVOCATION') -- FILTERS CO. THAT HAVE HAD AN INVOLUNTARY REVOCATION
                    and 
                    final_authority_action_description in ('DISCONTINUED REVOCATION') -- BUT THAT HAVE ALSO HAD THEIR INVOLUNTARY REVOCATION DISCONTINUED, MEANING THAT THEY'RE NOW ACTIVE. 
                    and
                    CAST(CAST(final_authority_served_date AS VARCHAR) AS DATE) >= DATEADD(DAY, -`+input1+`, GETDATE()))) r on b.DOT_NUMBER = right(r.usdot_number, 7)`            
        }else if (selectedCheck == 3){
            query = " and cast(cast(a.record_add_date as varchar) as date) BETWEEN '"+from+"' AND '"+to+"'"
        }else if (selectedCheck == 4){
            query = ` join (
                    select distinct * from (
                    select
                    rank()over(PARTITION by usdot_number order by cast(cast(final_authority_served_date as varchar) as date) desc
                    , cast(cast(final_authority_decision_date as varchar) as date) desc
                    , cast(cast(final_authority_served_date as varchar) as date) desc) as ranks -- RANKS BY final_authority_served_date DESC TO ENSURE LATEST INFORMATION IS UP TO DATE
                    , *
                    from authhist_allwithhistory aa
                    where usdot_number >= 1 -- THIS DISALLOWS USDOT#'S THAT ARE ONLY 0
                    ) a
                    where 
                    -- #1,2,3,4 FILTER TO ENSURE ONLY ACTIVE COMPANIES APPEAR IN QUERY. 	#5,6 FILTERS AUTHORITY ACTIVATED IN CERTAIN AMOUNT OF PREVIOUS DAYS
                    (ranks = 1 -- 1. THIS FILTERS ONLY MOST RECENT AUTHORITY HISTORY
                    and 
                    ORIGNAL_AUTHORITY_ACTION_DESCRIPTION IN ('REINSTATED','GRANTED') -- 2. FILTERS CO THAT MOST RECENT AUTH DESC IS REINSTATED OR GRANTED
                    and  
                    FINAL_AUTHORITY_ACTION_DESCRIPTION = '' -- 3. ALSO FITLERS WHERE NO FINAL AUTH DESC IS AVAILABLE. MEANING ORGINIAL AUTH DESC IS REINSTATED OR GRANTED WITH NO REVOCATION
                    and
                    LEN(ORIGNAL_AUTHORITY_ACTION_SERVED_DATE) > 0 -- 4. FILTERS WHERE THERES A ORIGINAL AUTH SERVED DATE PRESENT (SHOULDN'T BE EMPTY)
                    and 
                    (
                    cast(cast(ORIGNAL_AUTHORITY_ACTION_SERVED_DATE as varchar) as date) BETWEEN '`+from+`' AND '`+to+`' -- 5. THIS FILTERS TO QUERY COMPANIES WHO HAVE HAD THEIR AUTH GRANTED FROM ONE DATE TO ANOTHER
                    OR 
                    cast(cast(final_authority_served_date as varchar) as date) BETWEEN '`+from+`' AND '`+to+`' -- 6. THIS FILTERS TO QUERY CO. WHO HAVE WHO HAVE HAD THEIR AUTH REINSTATED FROM ONE DATE TO ANOTHER
                    )
                    ) 
                    OR 
                    (ranks = 1 -- THIS FILTERS ONLY MOST RECENT AUTHORITY HISTORY
                    and 
                    ORIGNAL_AUTHORITY_ACTION_DESCRIPTION in ('INVOLUNTARY REVOCATION') -- FILTERS CO. THAT HAVE HAD AN INVOLUNTARY REVOCATION
                    and 
                    final_authority_action_description in ('DISCONTINUED REVOCATION') -- BUT THAT HAVE ALSO HAD THEIR INVOLUNTARY REVOCATION DISCONTINUED, MEANING THAT THEY'RE NOW ACTIVE. 
                    and
                    cast(cast(final_authority_served_date as varchar) as date) BETWEEN '`+from+`' AND '`+to+`' -- FILTERS CO. FROM ONE DATE TO ANOTHER WHO HAVE HAD THEIR AUTH INVOLUNTARY REV, BUT THEN GOT THEIR REVOCATION DISCONTINUED
                    )) r on b.DOT_NUMBER = right(r.usdot_number, 7) 
                    `
        }else{
            setIsBusinessOpen(false)
            return
        }
        setUsdots({filter: selectedCheck, query: query})
        setIsBusinessOpen(false)
    }





    return (
        <>
            <Modal
                open={isBusinessOpen}
                onCancel={() => setIsBusinessOpen(false)}
                footer={null}
                width={797}
                closeIcon={null}
            >

                <div className="headerofmodal">
                    <span className="headertext">Business Length</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setIsBusinessOpen(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="coverofmodalforlength">
                    <div className="cardcomponent">
                        <div className="card-header">
                            New entrant
                        </div>
                        <div className="row_wrapper">

                            <div className="cover_of_modal_business">
                                <div className="check_cover">
                                    <div className="coverofchecks">
                                        <Checkbox checked={selectedCheck == 1} onChange={() => handleCheck(1)} className="custom-checkbox" value="1"> Add date:</Checkbox>

                                    </div>
                                    <div className="input_d mb-4">
                                        <div className="previous_days">
                                            Previous
                                        </div>
                                        <input value={input} disabled={selectedCheck != 1} onChange={handleInputChange} className="input_add_days" placeholder="30" >

                                        </input>
                                    </div>
                                    <div className="coverofchecks">
                                        days

                                    </div>
                                </div>
                                <div className="check_cover">

                                    <div className="coverofchecks">
                                        <Checkbox checked={selectedCheck == 2} onChange={() => handleCheck(2)} className="custom-checkbox" value="2"> Authority activated:</Checkbox>

                                    </div>
                                    <div className="input_days">
                                        <input value={input1} disabled={selectedCheck != 2} onChange={handleInputChange1} className="input_add_days" placeholder="30">

                                        </input>
                                    </div>
                                    <div className="coverofchecks">
                                        days

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="coverofmodalforlength">
                    <div className="cardcomponent">
                        <div className="card-header">
                            Established
                        </div>
                        <div className="row_wrapper">

                            <div className="cover_of_modal_business">
                                <div className="check_cover">
                                    <div className="coverofchecks">
                                        <Checkbox checked={selectedCheck == 3} onChange={() => handleCheck(3)} className="custom-checkbox" value="3"> Add date:</Checkbox>

                                    </div>
                                    <div className="input_days">
                                        <DatePicker
                                            style={{
                                                width: '156px',
                                                height: '40px',
                                                flexShrink: 0,
                                                borderRadius: '4px',
                                                border: '1px solid #DFE1E2',
                                                background: '#FFF',
                                            }}
                                            disabled={selectedCheck != 3}
                                            placeholder="30/00/0000"
                                            format="YYYY/MM/DD"
                                            onChange={handleDateChange}
                                            value={selectedDate}
                                            suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>}
                                        />  - <DatePicker
                                            style={{
                                                width: '156px',
                                                height: '40px',
                                                flexShrink: 0,
                                                borderRadius: '4px',
                                                border: '1px solid #DFE1E2',
                                                background: '#FFF',
                                                color: "#374147",
                                                fontFamily: "Roboto,Sans Sherif",
                                                fontWeight: "600"

                                            }}
                                            disabled={selectedCheck != 3}
                                            placeholder="30/00/0000"
                                            format="YYYY/MM/DD"
                                            onChange={handleDateChange1}
                                            value={selectedDate1}
                                            suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>}
                                        />
                                    </div>

                                </div>
                                <div className="check_cover mt-2">

                                    <div className="coverofchecks">
                                        <Checkbox checked={selectedCheck == 4} onChange={() => handleCheck(4)} className="custom-checkbox" value="4"> Authority activated:</Checkbox>

                                    </div>
                                    <div className="input_days">
                                        <DatePicker
                                            style={{
                                                width: '156px',
                                                height: '40px',
                                                flexShrink: 0,
                                                borderRadius: '4px',
                                                border: '1px solid #DFE1E2',
                                                background: '#FFF',
                                            }}
                                            disabled={selectedCheck != 4}
                                            placeholder="30/00/0000"
                                            format="YYYY/MM/DD"
                                            onChange={handleDateChange2}
                                            value={selectedDate2}
                                            suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>}
                                        />  - <DatePicker
                                            style={{
                                                width: '156px',
                                                height: '40px',
                                                flexShrink: 0,
                                                borderRadius: '4px',
                                                border: '1px solid #DFE1E2',
                                                background: '#FFF',
                                            }}
                                            disabled={selectedCheck != 4}
                                            placeholder="30/00/0000"
                                            format="YYYY/MM/DD"
                                            onChange={handleDateChange3}
                                            value={selectedDate3}
                                            suffixIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 2.25V5.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3.75 8.25H20.25" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="bottom_button_for_modal">
                    <span className="clear_Button" style={{ cursor: "pointer" }} onClick={() => { setIsBusinessOpen(false) }}>
                        Cancel
                    </span>
                    <div className="buttonofapply" onClick={handleApply}>
                        Apply
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default BusinessLength