import { useEffect, useState } from "react";
import "./Chameleon.css";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Hourglass } from 'react-loader-spinner';
import { BASE_URL, SERVER_URL } from "../Constants/BaseUrl";

function Chameleon() {
  const [vinnumber, setVinnumber] = useState("");
  const [usDot, setUsDot] = useState("");
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null)
  const [channum, setChannum] = useState(null)
  const [freetrail, setFreetrail] = useState(false)
  console.log(channum)
  useEffect(() => {
    // Fetch user ID from localStorage
    const userId = localStorage.getItem('userId');
    const fetchUserData = async (userId) => {
      try {
        const response = await axios.get(`${SERVER_URL}/userdata/chameleonCarrierSearches/${userId}`);
        setChannum(response.data.chameleonCarrierSearches);
        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        setFreetrail(userResponse.data.data.freeTrial);  // Corrected line
      } catch (error) {
        console.error('Error fetching user data:', error);

        // Handle error
      }
    };
    if (userId) {
      setUser(userId);
      fetchUserData(userId)
    }
  }, []);
  const validateVin = (vin) => /^[A-Za-z0-9]{17}$/.test(vin);
  const validateUsDot = (dot) => /^\d{1,7}$/.test(dot);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorText('');
    if (channum === 0) {
      setLoading(false);
      setErrorText('Your Search Limit Completed By this Plan Please Upgrade');
      return;
    }
    if (!vinnumber && !usDot) {
      setLoading(false);
      setErrorText('Please fill in the blanks!');
      return;
    }

    if (vinnumber && !validateVin(vinnumber)) {
      setLoading(false);
      setErrorText('Invalid VIN Number. It must be exactly 17 characters long and contain only letters (both uppercase and lowercase) and digits.');
      return;
    }

    if (usDot && !validateUsDot(usDot)) {
      setLoading(false);
      setErrorText('Invalid USDOT Number. It must be a numeric value ');
      return;
    }

    try {
      if (vinnumber) {
        const [response, response1] = await Promise.all([
          axios.post(`${BASE_URL}/getChameleonByVIN`, { vin_number: vinnumber }),
          axios.post(`${BASE_URL}/getInspectionDetailsByVIN`, { vin_number: vinnumber })
        ]);

        if (response.data.result && response1.data.result) {
          const data = response.data.result;
          const inspection_details = response1.data.result.inspection;
          const vehicles = response1.data.result.vehicle;
          setLoading(false);
          if(freetrail === false){
            await axios.put(`${SERVER_URL}/userdata/chameleonCarrierSearches/decrement/${user}`)
          }

          navigate('/sales/vin', { state: { data, inspection_details, vehicles, vin_number: vinnumber } });
        } else {
          throw new Error(response.data.error || response1.data.error);
        }
      } else if (usDot) {
        const [response, response1] = await Promise.all([
          axios.post(`${BASE_URL}/getChameleonByUSDOT`, { dot_number: usDot }),
          axios.post(`${BASE_URL}/getSimilarUSDOTCount`, { dot_number: usDot })
        ]);

        if (response.data.hasOwnProperty('result') && response1.data.hasOwnProperty('result')) {
          const data = response.data.result.data[0];
          const inspections = response.data.result.inspection.hasOwnProperty('error') ? [] : response.data.result.inspection;
          const crash = response.data.result.crash.hasOwnProperty('error') ? [] : response.data.result.crash;
          const crashReport = response.data.result.crashReport.hasOwnProperty('error') ? [] : response.data.result.crashReport;
          const similar_dots = response1.data.result;
          setLoading(false);
          if(freetrail === false){
            await axios.put(`${SERVER_URL}/userdata/chameleonCarrierSearches/decrement/${user}`)
          }
          navigate('/sales/usdot', { state: { data, inspections, similar_dots, crash, crashReport } });
        } else {
          throw new Error(response.data.error || response1.data.error);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorText('Error in fetching data: ' + error.message);
    }
  };

  const handleInputChange = (e, setInput) => {
    setErrorText('');
    setInput(e.target.value);
  };

  return (
    <div>
      <div className="Chameleon">
        <span className="Chameleontitle">Chameleon</span>
        <p className="Chameleontext">Uncover Entities Sharing Company Representative Names, Emails, Phone Numbers, Addresses, EIN Numbers, or VIN Numbers</p>
        <div className="warningtext">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#262526" stroke-width="1.5" stroke-miterlimit="10" />
            <path d="M8 5V8.5" stroke="#262526" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 11.5C8.41421 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.41421 10 8 10C7.58579 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.58579 11.5 8 11.5Z" fill="#262526" />
          </svg>
          {
            freetrail ? (
              <div className="textwarning">
              You have <b>Unlimited</b> searches available during free trial
            </div> 
            ) : (
              <div className="textwarning">
                You have <b>{channum}</b> remaining searches available
              </div>
            )
          }


        </div>
        <span className="usdottitle">USDOT Number</span>
        <input
          type="text"
          placeholder="Ex. 2284379"
          className="usdotinput"
          value={usDot}
          onChange={(e) => handleInputChange(e, setUsDot)}
        />
        <div className="lineofhr">
          <hr /><span className="orofhr">or</span><hr />
        </div>
        <span className="usdottitle">VIN Number</span>
        <input
          type="text"
          placeholder="Ex. 3C7WRSCLXMG648340"
          value={vinnumber}
          className="usdotinput"
          onChange={(e) => handleInputChange(e, setVinnumber)}
        />
        <button
          className="submitbtn butonhover"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </button>
        {errorText && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorText}
          </div>
        )}
      </div>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#306cce', '#72a1ed']}
          />
        </div>
      )}
    </div>
  );
}

export default Chameleon;
