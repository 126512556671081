import React, { useState, useEffect } from 'react';
import AccountNav from "../Navbar/AccountNav";
import userimage from "./Images/Profile-image.png";
import editimg from "./Images/editimg.png";
import previewimag from "./Images/preview.png";
import "./user.css";
import { Modal, Button } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../Constants/BaseUrl';

function Agencyinfo() {
  const [password, setPassword] = useState("3129akjdsh@kjs");
  const [type, setType] = useState("password");
  const [openedit, setOpenedit] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState({
    agencyName: '',
    contactName: '',
    authorizedRepresentative: '',
    faxNumber: '',
    phoneNumber: '',
    email: '',
    agencyAddress: '',
    suite: '',
    cityZipCode: '',
    state: ''
  });
  const[userinfo, setUserinfo]=useState('')
const [loading,setLoading]=useState(false)
  useEffect(() => {
    const userId = localStorage.getItem('userId');

    const fetchUserData = async () => {
      try {
        if (!userId) {
          console.error('User ID not found in localStorage');
          setLoading(false);
          return;
        }

        const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
        const userData = userResponse.data.data;

        setUserinfo(userData);
        setLoading(false); // Set loading to false after successful data fetch

      
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false); // Set loading to false on error
        // Handle additional error logic if needed
      }
    };

    fetchUserData();
  }, []);
const[user,setUser]=useState('')
  useEffect(() => {
    const userId = localStorage.getItem('userId');

    const fetchData = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/agencyinfo/person/${user}`);
        setAgencyInfo(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    if(userId){
      setUser(userId)
      fetchData();

    }
  }, [user]);

  const handletypeChange = () => {
    setType(type === "password" ? "text" : "password");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgencyInfo({ ...agencyInfo, [name]: value });
  };

  const handleSave = async () => {
    try {
      const subscriptionId = user
      const data = { ...agencyInfo, user, subscriptionId };
      await axios.post(`${SERVER_URL}/agencyinfo/createagency`, data);
      setOpenedit(false);
    } catch (error) {
      console.error('Error saving data', error);
    }
  };

  return (
    <div className='user_main'>
      <div>
        <AccountNav />
      </div>
      <div className='main_content'>
        <div className='inner_account'>
          {/* user name email profile image section */}
          <div className='name_email_section'>
          <div className='profile_image'>
    <div className='nameletter'>
      {userinfo?.billingAddress?.firstName?.charAt(0).toUpperCase() || ''}
    </div>
  </div>
            <div className='namesection'>
              <div className='username'>{userinfo?.companyName}</div>
              <div className='useremail'>{userinfo?.billingAddress?.firstName}</div>
            </div>
          </div>

          {/* user all information  */}
          <div className='boxinfo'>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Name: </div>
                <div className='usertitleinfo'>{userinfo?.companyName}</div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency DBA:</div>
                <div className='usertitleinfo'>N/A</div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Address:</div>
                <div className='usertitleinfo'>{userinfo?.billingAddress?.address}</div>
              </div>
              <div className='d-flex gap-3'>
                <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
              </div>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Phone: </div>
                <div className='usertitleinfo'>{userinfo?.phoneNumber}</div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Owner Name:</div>
                <div className='usertitleinfo'>{userinfo?.billingAddress?.firstName}</div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Owner Email:</div>
                <div className='usertitleinfo'>{userinfo?.email}</div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_grey'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Default COI Agency:</div>
                <div className='usertitleinfo'>Preview <img className='editimage' src={previewimag} alt='edit image'></img></div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
            <div className='userstrap_white'>
              <div className='d-flex gap-3'>
                <div className='usertitle'>Agency Logo: </div>
                <div className='usertitleinfo'>Preview <img className='editimage' src={previewimag} alt='edit image'></img></div>
              </div>
              <img className='editimage' src={editimg} onClick={() => { setOpenedit(true) }} alt='edit image'></img>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openedit}
        onCancel={() => setOpenedit(false)}
        footer={null}
        closeIcon={null}
        width={600}
      >
        <div className="headerofmodal">
          <span className="headertext">Default Certificate of Insurance Info </span>
          <svg style={{ cursor: "pointer" }} onClick={() => { setOpenedit(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="bodyofmodal">
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Agency Name:</div>
              <input
                type="text"
                name="agencyName"
                placeholder="ABC Insurance Agency, LLC"
                className="usdotinput"
                value={agencyInfo.agencyName}
                onChange={handleInputChange}
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Contact Name:</div>
              <input
                type="text"
                name="contactName"
                placeholder="Name"
                className="usdotinput"
                value={agencyInfo.contactName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Authorized Representative: </div>
              <input
                type="text"
                name="authorizedRepresentative"
                placeholder="Name"
                className="usdotinput"
                value={agencyInfo.authorizedRepresentative}
                onChange={handleInputChange}
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Fax Number: </div>
              <input
                type="text"
                name="faxNumber"
                placeholder="Fax Number"
                className="usdotinput"
                value={agencyInfo.faxNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Phone Number: </div>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                className="usdotinput"
                value={agencyInfo.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Email: </div>
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="usdotinput"
                value={agencyInfo.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>Agency Address:  </div>
              <input
                type="text"
                name="agencyAddress"
                placeholder="Agency Address"
                className="usdotinput"
                value={agencyInfo.agencyAddress}
                onChange={handleInputChange}
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>Suite: </div>
              <input
                type="text"
                name="suite"
                placeholder="Suite"
                className="usdotinput"
                value={agencyInfo.suite}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='inputlabelcover'>
            <div className='left_input'>
              <div className='labelofinput'>City & Zip Code:  </div>
              <input
                type="text"
                name="cityZipCode"
                placeholder="City & Zip Code"
                className="usdotinput"
                value={agencyInfo.cityZipCode}
                onChange={handleInputChange}
              />
            </div>
            <div className='left_input'>
              <div className='labelofinput'>State: </div>
              <input
                type="text"
                name="state"
                placeholder="State"
                className="usdotinput"
                value={agencyInfo.state}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="footerofmodal">
            <Button type="primary" onClick={handleSave}>Save</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Agencyinfo;
