import { SERVER_URL } from '../Constants/BaseUrl';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {  Select, message } from 'antd';
const { Option } = Select;
const stateMapping = {
    "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA",
    "Colorado": "CO", "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA",
    "Hawaii": "HI", "Idaho": "ID", "Illinois": "IL", "Indiana": "IN", "Iowa": "IA",
    "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA", "Maine": "ME", "Maryland": "MD",
    "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN", "Mississippi": "MS", "Missouri": "MO",
    "Montana": "MT", "Nebraska": "NE", "Nevada": "NV", "New Hampshire": "NH", "New Jersey": "NJ",
    "New Mexico": "NM", "New York": "NY", "North Carolina": "NC", "North Dakota": "ND", "Ohio": "OH",
    "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA", "Rhode Island": "RI", "South Carolina": "SC",
    "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX", "Utah": "UT", "Vermont": "VT",
    "Virginia": "VA", "Washington": "WA", "West Virginia": "WV", "Wisconsin": "WI", "Wyoming": "WY"
  };
function StateSelection() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [statesAllowed, setStatesAllowed]=useState(null)
    const [selectedStates, setSelectedStates] = useState([]);
    const stateOptions = Object.keys(stateMapping).map(fullName => ({
        fullName,
        abbreviation: stateMapping[fullName]
      }));
    
      const handleCloseModal = () => {
        setVisible(false);
      };
    
      const handleChange = (value) => {
        if (value.length <= statesAllowed) {
          setSelectedStates(value);
        }
      };
   
      const handleSubmit = async () => {
        try {
          // Convert full names to abbreviations
          const selectedAbbreviations = selectedStates.map(fullName => stateMapping[fullName]);
    
          await axios.put(`${SERVER_URL}/userdata/${user._id}`, {
            states: selectedAbbreviations,
          });
    
          message.success('States updated successfully');
          handleCloseModal();
          // Reload the page after successful submission
    window.location.reload();

        } catch (error) {
          message.error('Error updating states');
          console.error('Error updating states:', error);
        }
      };
    useEffect(() => {
      const userId = localStorage.getItem('userId');
    
      const fetchUserData = async () => {
        try {
          if (!userId) {
            console.error('User ID not found in localStorage');
            setLoading(false);
            return;
          }
    
          const userResponse = await axios.get(`${SERVER_URL}/users/${userId}`);
          const userData = userResponse.data.data;
    
          setUser(userData);
          setLoading(false); // Set loading to false after successful data fetch
    
          if (userData.purchasedcategory === 'Starter' || userData.purchasedcategory === 'Business') {
            setStatesAllowed(userData.purchasedcategory === 'Starter' ? 1 : 3);
    
            const stateInfoResponse = await axios.get(`${SERVER_URL}/userdata/${userId}`);
            const stateInfoData = stateInfoResponse.data.user.states;
    
            // Check if states is an array
            if (Array.isArray(stateInfoData)) {
              if (stateInfoData.length === 0) {
                setVisible(true);
              }
            } else {
              console.log('states is not an array or is undefined');
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setLoading(false); // Set loading to false on error
          // Handle additional error logic if needed
        }
      };
    
      fetchUserData();
    }, []);
  return (
    <div>
         <div className='p-4'>
          <div className='cancelbtn mb-3'>This action is irreversible: once you select the states for Leads, it cannot be changed.</div>

          <Select
            mode="multiple"
            placeholder="Please select states"
            value={selectedStates}
            onChange={handleChange}
            style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: '4px' }}
          >
            {stateOptions.map(({ fullName, abbreviation }) => (
              <Option key={abbreviation} value={fullName}>
                {fullName}
              </Option>
            ))}
          </Select>

          <div className='footerButton' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <div className='cancelbtn' onClick={handleCloseModal} style={{ cursor: 'pointer' }}>Cancel</div>
            <div className='submitbutton' onClick={handleSubmit} style={{ cursor: 'pointer' }}>Submit</div>
          </div>
        </div>

    </div>
  )
}

export default StateSelection