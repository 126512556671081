import React, { useState } from 'react';
import { Form, Radio, Row, Col, Select } from 'antd';

import "../carier.css";
import BarGraph from './BarGraph';

const InsuranceCompanyPage = () => {
  const [locationType, setLocationType] = useState('state');

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const handleLocationChange = (e) => {
    setLocationType(e.target.value);
  };


 
  return (
    <div className="text-dark form-container">
      <Form layout="vertical" onFinish={onFinish}>
        <div className="radio-group-container">
          <Form.Item>
            <Radio.Group onChange={handleLocationChange} value={locationType}>
              <Radio value="state">State</Radio>
              <Radio value="city">City</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Row gutter={16} className="select-container">
          <Col span={12}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: locationType === 'state', message: 'Please select your state!' }]}
            >
              <Select className='select-state' disabled={locationType !== 'state'}>
                <Select.Option value="lorem">Lorem ipsum dolor</Select.Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: locationType === 'city', message: 'Please select your city!' }]}
            >
              <Select className='select-state' disabled={locationType !== 'city'}>
                <Select.Option value="00000">00000</Select.Option>
                {/* Add more options as needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <BarGraph/>
      <div className="bottom_button">
        <span className="clear_Button">
          Clear
        </span>
        <div className="buttonofsubmit butonhover">
          Submit
        </div>
      </div>
    </div>
  );
};

export default InsuranceCompanyPage;
