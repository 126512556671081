import { create } from 'zustand';
import axios from 'axios';



const businessLengthstore = create((set) => ({
  businessLengths: [],
  selectedDate: null,
  selectedDate1: null,
  selectedDate2: null,
  selectedDate3: null,
  input: "",
  input1: "",
  selectedCheck: null,
  from: null,
  to: null,
  isLoading: false,
  error: null,

  // Functions to update the states

  handleCheck: (index) => {
    set((state) => ({
      selectedCheck: state.selectedCheck === index ? null : index,
    }));
  },

  handleDateChange: (date, dateString) => {
    set({
      selectedDate: date,
      from: dateString,
    });
  },

  handleDateChange1: (date, dateString) => {
    set({
      selectedDate1: date,
      to: dateString,
    });
  },

  handleDateChange2: (date, dateString) => {
    set({
      selectedDate2: date,
      from: dateString,
    });
  },

  handleDateChange3: (date, dateString) => {
    set({
      selectedDate3: date,
      to: dateString,
    });
  },

  handleInputChange: (event) => {
    set({
      input: event.target.value,
    });
  },

  handleInputChange1: (event) => {
    set({
      input1: event.target.value,
    });
  },

  clearStates: () => {
    set({
      selectedDate: null,
      selectedDate1: null,
      selectedDate2: null,
      selectedDate3: null,
      input: "",
      input1: "",
      selectedCheck: null,
      from: null,
      to: null,
    });
  },
}));

export default businessLengthstore;
