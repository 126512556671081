import { Modal } from "antd";
import { useState } from "react";

function Combodies() {
    const [condition, setCondition] = useState('');
    const [percentage, setPercentage] = useState('');
    const [commodities, setCommodities] = useState([]);
    const [commodal, setCommodal] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [editCondition, setEditCondition] = useState('');
    const [editPercentage, setEditPercentage] = useState('');
    const handleSaveCommodity = () => {
        if (editIndex === -1) {
            // Add new commodity
            setCommodities([...commodities, { condition, percentage }]);
        } else {
            // Update existing commodity
            const updatedCommodities = [...commodities];
            updatedCommodities[editIndex] = { condition: editCondition, percentage: editPercentage };
            setCommodities(updatedCommodities);
            // Reset edit state
            setEditIndex(-1);
        }
        // Clear input fields
        setCommodal(false)
        setCondition('');
        setPercentage('');
        setEditCondition('');
        setEditPercentage('');
    };
    // Function to handle editing commodity
    const handleEditCommodity = (index) => {
        setCommodal(true)
        const commodityToEdit = commodities[index];
        setEditIndex(index);
        setEditCondition(commodityToEdit.condition);
        setEditPercentage(commodityToEdit.percentage);
    };

    // Function to handle deleting commodity
    const handleDeleteCommodity = (index) => {
        const updatedCommodities = [...commodities];
        updatedCommodities.splice(index, 1);
        setCommodities(updatedCommodities);
    };
    return (
        <>
            <div className="cardcomponent">
                <div className="card-header">
                    Commodities
                </div>
                {
                    commodities.length === 0 ? (<>

                    </>) : (<div className="coverofcomb">
                        {
                            commodities.map((com, index) => {
                                return (
                                    <>

                                        <div className="leftofcomb">
                                            <div className="targetofcom">
                                                <div className="coverofcom">
                                                    <span className="titleofinfo ">
                                                        Number:                                            </span>
                                                    <span className="descofinfo ">
                                                        #{index + 1}    </span>
                                                </div>
                                                <div className="coverofcom">
                                                    <span className="titleofinfo">
                                                        Type:                                            </span>
                                                    <span className="descofinfo">
                                                        {com.condition}                                            </span>
                                                </div>
                                                <div className="coverofcom">
                                                    <span className="titleofinfo">
                                                        Porcentage:                                            </span>
                                                    <span className="descofinfo">
                                                        {com.percentage}%                                           </span>

                                                </div>
                                            </div>

                                            <div className="customizebtn">
                                                <div className="editbtn">
                                                    <svg onClick={() => handleEditCommodity(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M6 13.4999H3C2.86739 13.4999 2.74022 13.4473 2.64645 13.3535C2.55268 13.2597 2.5 13.1326 2.5 12.9999V10.2062C2.49978 10.1413 2.51236 10.0769 2.53702 10.0169C2.56169 9.95682 2.59796 9.90222 2.64375 9.85619L10.1438 2.3562C10.1903 2.30895 10.2457 2.27144 10.3069 2.24583C10.3681 2.22022 10.4337 2.20703 10.5 2.20703C10.5663 2.20703 10.632 2.22022 10.6931 2.24583C10.7543 2.27144 10.8097 2.30895 10.8563 2.3562L13.6438 5.1437C13.691 5.19022 13.7285 5.24568 13.7541 5.30684C13.7797 5.368 13.7929 5.43364 13.7929 5.49995C13.7929 5.56625 13.7797 5.63189 13.7541 5.69305C13.7285 5.75421 13.691 5.80967 13.6438 5.85619L6 13.4999Z" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M13.5 13.5H6" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.5 4L12 7.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="deletebtn">
                                                    <svg onClick={() => handleDeleteCommodity(index)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M13.5 3.5H2.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M6.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M9.5 6.5V10.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M12.5 3.5V13C12.5 13.1326 12.4473 13.2598 12.3536 13.3536C12.2598 13.4473 12.1326 13.5 12 13.5H4C3.86739 13.5 3.74021 13.4473 3.64645 13.3536C3.55268 13.2598 3.5 13.1326 3.5 13V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.5 3.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V3.5" stroke="#374147" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>



                                    </>
                                )
                            })

                        }

                    </div>

                    )
                }
                <div className="addvehicle addvehi" onClick={() => { setCommodal(true) }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6.875 10H13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 6.875V13.125" stroke="#0C94E4" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Add sub-portfolios</span>
                </div>


            </div>


            <Modal
                open={commodal}
                onCancel={() => setCommodal(false)}
                footer={null}
                closeIcon={null}
                width={600}
            >
                <div className="headerofmodal">
                    <span className="headertext">Add commodities</span>
                    <svg style={{ cursor: "pointer" }} onClick={() => { setCommodal(false) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M16 2L2 16" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M16 16L2 2" stroke="#374147" strokeWidth="2.625" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="bodyofmodal">
                    <div className="inputfileds">
                        <div className="rightsideinput">
                            <span className="boderheader">Type:</span>
                            <input
                                type="text"
                                placeholder="Ex. 2000"
                                className="usdotinput"
                                value={editIndex === -1 ? condition : editCondition}
                                onChange={(e) => editIndex === -1 ? setCondition(e.target.value) : setEditCondition(e.target.value)}
                            />
                        </div>
                        <div className="leftsideinput">
                            <span className="boderheader">Percentage:</span>


                            <input
                                type="text"
                                placeholder="Make"
                                className="usdotinput"
                                value={editIndex === -1 ? percentage : editPercentage}
                                onChange={(e) => editIndex === -1 ? setPercentage(e.target.value) : setEditPercentage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="footerofmodal">
                        <span className="cancelbutton" onClick={() => { setCommodal(false) }}>Cancel</span>
                        <div className="submitbutton" onClick={handleSaveCommodity}>
                            {editIndex === -1 ? 'Save Commodity' : 'Update Commodity'}
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    )
}
export default Combodies;