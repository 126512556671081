import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import CustomTooltip from "../CustomTooltip";
import Sendmessage from "../Sendmessage";

const CoverDetail = () => {
  const messageboxRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (messageboxRef.current && !messageboxRef.current.contains(event.target)) {
        setClicked(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <>
      <div className="coverofdetail">
        <div className="headercontain">
          <Link to="/home/mail">
            <span className="backtopost">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <path d="M6.25 8.125L3.125 5L6.25 1.875" stroke="#666D72" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
              </svg> Back to List
            </span>
          </Link>
          <div className="iconsofcarier">
            <CustomTooltip text="Follow up">
              <div className="svgcontaindownload">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#374147" stroke-width="1.5" stroke-miterlimit="10" />
                  <path d="M4.98438 15.5781C5.45462 14.6519 6.17216 13.874 7.05745 13.3306C7.94275 12.7872 8.96123 12.4995 10 12.4995C11.0388 12.4995 12.0572 12.7872 12.9425 13.3306C13.8278 13.874 14.5454 14.6519 15.0156 15.5781" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.75 4.375H17.5" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.625 2.5V6.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.4063 8.82033C17.4694 9.21037 17.5008 9.60489 17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4002 14.2406 16.3615 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53683 17.3559C7.08197 17.0665 5.7456 16.3522 4.6967 15.3033C3.64781 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50325 8.50033 3.07091 7.12989C3.63856 5.75944 4.59986 4.5881 5.83323 3.76399C7.0666 2.93988 8.51664 2.50001 10 2.50001C10.3951 2.49924 10.7897 2.53059 11.1797 2.59376" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div></CustomTooltip>
            <CustomTooltip text="Download">
              <div className="svgcontaindownload2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M6.71875 8.59375L10 11.875L13.2812 8.59375" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10 3.125V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 11.875V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div></CustomTooltip>
            <CustomTooltip text="Print">
              <div className="svgcontaindownload2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5 6.25V3.125H15V6.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15 11.875H5V17.1875H15V11.875Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5 13.75H2.1875V7.5C2.1875 6.8125 2.79687 6.25 3.53906 6.25H16.4609C17.2031 6.25 17.8125 6.8125 17.8125 7.5V13.75H15" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.6875 10C15.2053 10 15.625 9.58027 15.625 9.0625C15.625 8.54473 15.2053 8.125 14.6875 8.125C14.1697 8.125 13.75 8.54473 13.75 9.0625C13.75 9.58027 14.1697 10 14.6875 10Z" fill="#374147" />
                </svg>
              </div></CustomTooltip>
            <CustomTooltip text="Comment">
              <div className="svgcontaindownload3  position-relative " data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M11.6953 15.3047L10.5391 17.2344C10.4824 17.3265 10.4032 17.4025 10.3088 17.4553C10.2144 17.508 10.1081 17.5357 10 17.5357C9.89189 17.5357 9.78558 17.508 9.69121 17.4553C9.59684 17.4025 9.51756 17.3265 9.46094 17.2344L8.30469 15.3047C8.2503 15.2121 8.17269 15.1352 8.07952 15.0818C7.98635 15.0284 7.88084 15.0002 7.77344 15H3.125C2.95924 15 2.80027 14.9342 2.68306 14.8169C2.56585 14.6997 2.5 14.5408 2.5 14.375V4.375C2.5 4.20924 2.56585 4.05027 2.68306 3.93306C2.80027 3.81585 2.95924 3.75 3.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V14.375C17.5 14.5408 17.4342 14.6997 17.3169 14.8169C17.1997 14.9342 17.0408 15 16.875 15H12.2266C12.1192 15.0002 12.0137 15.0284 11.9205 15.0818C11.8273 15.1352 11.7497 15.2121 11.6953 15.3047V15.3047Z" stroke="#374147" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10 10.3125C10.5178 10.3125 10.9375 9.89277 10.9375 9.375C10.9375 8.85723 10.5178 8.4375 10 8.4375C9.48223 8.4375 9.0625 8.85723 9.0625 9.375C9.0625 9.89277 9.48223 10.3125 10 10.3125Z" fill="#374147" />
                  <path d="M6.25 10.3125C6.76777 10.3125 7.1875 9.89277 7.1875 9.375C7.1875 8.85723 6.76777 8.4375 6.25 8.4375C5.73223 8.4375 5.3125 8.85723 5.3125 9.375C5.3125 9.89277 5.73223 10.3125 6.25 10.3125Z" fill="#374147" />
                  <path d="M13.75 10.3125C14.2678 10.3125 14.6875 9.89277 14.6875 9.375C14.6875 8.85723 14.2678 8.4375 13.75 8.4375C13.2322 8.4375 12.8125 8.85723 12.8125 9.375C12.8125 9.89277 13.2322 10.3125 13.75 10.3125Z" fill="#374147" />
                </svg>  <span class="position-absolute top-1 start-100 translate-middle reddoted">
                  <span class="visually-hidden">New alerts</span>
                </span>
              </div></CustomTooltip>
            <CustomTooltip text="Generate report">
              <div className="svgcontaindownload2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15.625 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V16.875C16.25 17.0408 16.1842 17.1997 16.0669 17.3169C15.9497 17.4342 15.7908 17.5 15.625 17.5Z" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.875 2.5V6.875H16.25" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.125 11.875H11.875" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10 10V13.75" stroke="#374147" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div></CustomTooltip>
            <CustomTooltip text="Send mail">
              <Sendmessage />
            </CustomTooltip>
          </div>
        </div>
        <div className="usdottitled">
          USDOT Number: #2284379
        </div>
        <div className="cardcomponent">
          <div className="card-header">
            Company information
          </div>
          <div className="row_wrapper">
            <div className="row_content">
              <div className="contentofindual">
                <div className="data_title">USDOT Number:</div>
                <div className="data_desc mt-1">3986854</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Prefix:</div>
                <div className="data_desc mt-1">MC</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Docket number:</div>
                <div className="data_desc mt-1">1494472</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Legal name:</div>
                <div className="data_desc mt-1">Golden eagle USA INC</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Partners:</div>
                <div className="data_desc mt-1" style={{ width: "79px" }}>Yi Yue
                  Yubin Zhang</div>

              </div>
            </div>
            <div className="row_content">
              <div className="contentofindual">
                <div className="data_title">DBA name:</div>
                <div className="data_desc mt-1">-</div>
              </div>

              <div className="contentofindual">
                <div className="data_title">City:</div>
                <div className="data_desc mt-1">Starfford</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">State:</div>
                <div className="data_desc mt-1" >Tx</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Phone</div>
                <div className="data_desc mt-1" >(626) 731-8878</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Cellphone:</div>
                <div className="data_desc mt-1" >(626) 731-8878</div>
              </div>

            </div>
            <div className="row_content">
              <div className="contentofindual">
                <div className="data_title">Address::</div>
                <div className="data_desc mt-1" style={{ width: "177px" }}>12733 Starfood RD STE 300
                  Stafford, TX 27477</div>
              </div>
              <div className="contentofindual">
                <div className="data_title">Fillial:</div>
                <div className="data_desc mt-1" style={{ width: "177px" }}>4622 Shipmans Cove BlVD
                  Fresno, TX 77545-2173</div>                                        </div>
              <div className="contentofindual">
                <div className="data_title"></div>
                <div className="data_desc mt-1"></div>
              </div>
              <div className="contentofindual">
                <div className="data_title"></div>
                <div className="data_desc mt-1" style={{ maxWidth: "146px" }}></div>
              </div>
              <div className="contentofindual">
                <div className="data_title"></div>
                <div className="data_desc mt-1" style={{ maxWidth: "146px" }}></div>
              </div>


            </div>

          </div>

        </div>
        <div className="cardcomponent">
          <div className="card-header">
            Company information
          </div>
          <div className="row_wrapper">
            <div className="row_content">
              <div className="contentofindual contentd">
                <div className="titleabove">1 Years in Business:</div>
                <div className="titleabove">Date added:<strong>Nov 16,2022</strong>  | MCS 150:<strong>Feb 22,2023</strong> </div>
                <div className="titleabove">Common: <strong>Active</strong>  | Contract: <strong>None</strong> | Broker:  <strong>None</strong></div>
                <div className="titleabove">Hazmat: <strong>N</strong> | OOS: <strong>N</strong> </div>

              </div>
              <div className="contentofindual contentd">
                <div className="titleabove">Vehicles:</div>
                <div className="titleabove">Truck: <strong>1</strong> | Owned: <strong>1</strong> | Leased: <strong>0</strong> </div>
                <div className="titleabove">Buses: <strong>1</strong> | Owned: <strong>1</strong> | Leased: <strong>0</strong> </div>
                <div className="titleabove">Drivers: <strong>N</strong> | CDL Drivers: <strong>N</strong> </div>

              </div>
              <div className="contentofindual contentd">
                <div className="titleabove">Current insurance company:</div>
                <strong className="stronletter">WESCO Insurance company</strong>
                <div className="titleabove">BIPD/ Primary | Federal</div>
                <div className="titleabove">Policy:<strong>WMC1989924</strong>  | BIPD req: <strong>002023 </strong>| BDC3:<strong>X</strong> </div>
                <div className="titleabove">Effective:<strong>Feb 14, 2023</strong>  | Expiration: <strong>Feb 14, 2023</strong></div>
                <div className="titleabove">Cancellation:<strong> N/A</strong></div>

              </div>

            </div>


          </div>

        </div>
      </div>

    </>
  )
}
export default CoverDetail;