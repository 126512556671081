import React from 'react';
import { Modal, Menu } from 'antd';
import { MailOutlined, DownloadOutlined, CalendarOutlined, SaveOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
const SalesSearchModal = ({ sending, reportData,sendfile, isModalVisible, setIsModalVisible, downloadReport,downloadpr }) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalVisible(false);
  };

  console.log(reportData,"reportData++++")
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleMenuItemClick = () => {
    navigate('/sales/LeadsResults', { state: { reportData } });
  };
  
  return (
    <>
      <Modal
        title={
          <div style={{ color: 'rgba(55, 65, 71, 1)' }}>
            Sales search - {reportData !== null ? reportData.length : 0} carriers found!
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="dark-modal rounded"
      >
     {
downloadpr ? (
<div className='d-flex justify-content-center p-5'>
<Hourglass/>


</div>
):( <Menu className="menu-content">
  <Menu.Item  key="email" icon={<MailOutlined />} onClick={sendfile}>
  {sending ?(<>Sending Email....</>):(<>Email report</>)}  
  </Menu.Item>
  <Menu.Item key="download" icon={<DownloadOutlined />} onClick={() => downloadReport()}>
    Download report
  </Menu.Item>
  {/* <Menu.Item key="schedule" icon={<CalendarOutlined />}>
    Schedule report
  </Menu.Item>
  <Menu.Item key="save" icon={<SaveOutlined />}>
    Save search
  </Menu.Item> */}
  <Menu.Item key="view" icon={<EyeOutlined />} onClick={handleMenuItemClick}>
    View results
  </Menu.Item>
</Menu>)
     }  
      </Modal>
      <style jsx>{`
        .dark-modal .ant-modal-header {
          background-color: rgba(223, 225, 226, 1);
          padding: 13px;
        }
        .menu-content {
          display: flex;
          flex-direction: column;
          height: 200px; /* Adjust height as needed */
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default SalesSearchModal;
