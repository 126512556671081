import { useState } from "react";

const InspectionDate = () => {
    const [Vehicles, setVehicles] = useState([
        { Type: "2007", Platenumber: "Freightliner", State: "Columbia", Platestate: "1FUJA6CK17PY50909", Timeweight: "Tractor", },
        { Type: "2007", Platenumber: "Freightliner", State: "Columbia", Platestate: "1FUJA6CK17PY50909", Timeweight: "Tractor", },
        { Type: "2007", Platenumber: "Freightliner", State: "Columbia", Platestate: "1FUJA6CK17PY50909", Timeweight: "Tractor", },
    ]);
    return (
        <>
            <div className="cardcomponent mycard">
                <div className="card-header">
                    Inspection date
                </div>
                <div className="coverofvehicle">
                    {
                        Vehicles.map((vehicle, index) => {
                            return (
                                <>
                                    <div className="coverofperson2 mt-3">

                                        <div className="target">
                                            <span className="titleofinfo">
                                                Type:
                                            </span>
                                            <span className="descofinfo">
                                                {vehicle.Type}
                                            </span>
                                        </div>

                                        <div className="target">
                                            <span className="titleofinfo">
                                                Plate number:
                                            </span>
                                            <span className="descofinfo">
                                                {vehicle.Platenumber}
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                                State:
                                            </span>
                                            <span className="descofinfo">
                                                {vehicle.State}
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                                Plate state:
                                            </span>
                                            <span className="descofinfo">
                                                {vehicle.Platestate}
                                            </span>
                                        </div>
                                        <div className="target">
                                            <span className="titleofinfo">
                                                Time weight (TW):
                                            </span>
                                            <span className="descofinfo">
                                                {vehicle.Timeweight}
                                            </span>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }

                    <div className="tablecontent">
                        <div className="coveraround">


                            <div className="fordate">
                                <div className="contentareafortable">
                                    <span className="tabletitle">Date:</span>
                                    <span className="tabledesc">9/22/2023</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle">Plate number:</span>
                                    <span className="tabledesc">5DY2900</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle">State:</span>
                                    <span className="tabledesc">PA</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle">Plate state:</span>
                                    <span className="tabledesc">MD</span>
                                </div>


                                <div className="contentareafortable">
                                    <span className="tabletitle">Time weight (TW):</span>
                                    <span className="tabledesc">3</span>
                                </div>
                            </div>




                            <div className="headeroftable2">
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Unit:</span>
                                    <span className="tabledesc2">1</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Type:</span>
                                    <span className="tabledesc2">Straight Truck</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Make:</span>
                                    <span className="tabledesc2">RAM</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Plate state:</span>
                                    <span className="tabledesc2">MD</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Plate Number:</span>
                                    <span className="tabledesc2">7EY0572</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Vin:</span>
                                    <span className="tabledesc2">3C7WRSCL1MG648341</span>
                                </div>
                                <div className="contentareafortable">
                                    <span className="tabletitle2">Severtiy weight</span>
                                    <span className="tabletitle2">(SW)</span>
                                </div>

                            </div>
                            <span className="tabledesc2" style={{ paddingTop: "0px", paddingLeft: "30px" }}>2</span>
                            <div className="alltextdocument">
                                <span className="tabletitle2">Vehicle maint:</span>
                                <div className="coveroftext">
                                    <span className="vehicalmaint">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint1">3 (OOS)</span>

                                </div>
                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">2 + 2 (005)</span>

                                </div>
                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">4 (OOS)</span>

                                </div>
                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">2 (OOS)</span>

                                </div>
                                <div className="coveroftext">
                                    <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                    <span className="vehicalmaint3">2 (OOS)</span>

                                </div>
                                <div className="buttonfordetail">
                                    More Details
                                </div>

                            </div>
                            <div className="secondpart">
                                <div className="headeroftable2">
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Unit:</span>
                                        <span className="tabledesc2">1</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Type:</span>
                                        <span className="tabledesc2">Straight Truck</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Make:</span>
                                        <span className="tabledesc2">RAM</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Plate state:</span>
                                        <span className="tabledesc2">MD</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Plate Number:</span>
                                        <span className="tabledesc2">7EY0572</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Vin:</span>
                                        <span className="tabledesc2">3C7WRSCL1MG648341</span>
                                    </div>
                                    <div className="contentareafortable">
                                        <span className="tabletitle2">Severtiy weight</span>
                                        <span className="tabletitle2">(SW)</span>
                                    </div>

                                </div>
                                <div className="alltextdocument">
                                    <span className="tabletitle2">Vehicle maint:</span>

                                    <div className="coveroftext">
                                        <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                        <span className="vehicalmaint3">2 + 2 (005)</span>

                                    </div>
                                    <div className="coveroftext">
                                        <span className="vehicalmaint2">Vehicle maint: Violation: 393.111 No or detective lighthing devices or reflactive material as required</span>
                                        <span className="vehicalmaint3">4 (OOS)</span>

                                    </div>

                                    <div className="buttonfordetail">
                                        More Details
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default InspectionDate;